import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { APIService, CreateAppStateInput } from "./API.service";

@Injectable({
  providedIn: "root",
})
export class MaintenanceGuard implements CanActivate {
  constructor(private api: APIService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.api.GetAppState("1692491274083").then((state) => {
      if (state.maintenanceMode) this.router.navigate(["/maintenancemode"]);
      return false;
    });
    return true;
  }
}
