import { Component, ViewChild, ViewContainerRef } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";

@Component({
  selector: "app-formly-wrapper-title",
  template: `
    <h3> <strong>{{ to.label }}</strong></h3>

    <ng-container #fieldComponent></ng-container>
  `,
})
export class TitleWrapperComponent extends FieldWrapper {
  @ViewChild("fieldComponent", { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;
}
