import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { SplashScreen } from "@capacitor/splash-screen";
import { Preferences } from "@capacitor/preferences";

import { TranslateService } from "@ngx-translate/core";

import { HistoryHelperService } from "./utils/history-helper.service";
import { AuthenticatorService } from "@aws-amplify/ui-angular";
import { Auth } from "aws-amplify";
import { APIService } from "./API.service";

import { ZenObservable } from "zen-observable-ts";
import { BehaviorSubject, Observable, of } from "rxjs";

import { MaintenanceModeService } from "./maintenancemode/maintenancemode.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: [
    "./side-menu/styles/side-menu.scss",
    "./side-menu/styles/side-menu.shell.scss",
    "./side-menu/styles/side-menu.responsive.scss",
  ],
})
export class AppComponent implements OnInit {
  private subscription: ZenObservable.Subscription | null = null;

  appid = "1692491274083";
  loggedIn: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  usr: Observable<any> = this.loggedIn.asObservable();
  authUrl = "/auth/login";
  isAdmin = false;
  maintainer = false;
  shouldCreate = true;
  textDir = "ltr";

  appPages = [
    {
      title: "Home",
      url: "/home",
      ionicIcon: "list-outline",
    },
    /*
    {
      title: "Blog",
      url: "/blog",
      ionicIcon: "list-outline",
    },
     {
      title: 'Categories',
      url: '/app/categories',
      ionicIcon: 'list-outline'
    },
    {
      title: 'Profile',
      url: '/app/user',
      ionicIcon: 'person-outline'
    },
    {
      title: 'Contact Card',
      url: '/contact-card',
      customIcon: './assets/custom-icons/side-menu/contact-card.svg'
    },
    {
      title: 'Notifications',
      url: '/app/notifications',
      ionicIcon: 'notifications-outline'
    }
    */
  ];

  accountPages = [
    {
      title: "Log In",
      url: "/auth/login",
      ionicIcon: "log-in-outline",
    } /*
    {
      title: "Log Out",
      url: "/auth/signup",
      ionicIcon: "log-out-outline",
    },*/,
    /*{
      title: "Sign Up",
      url: "/auth/signup",
      ionicIcon: "person-add-outline",
    },*/
    /*   {
      title: 'Getting Started',
      url: '/getting-started',
      ionicIcon: 'rocket-outline'
    },
    {
      title: '404 page',
      url: '/page-not-found',
      ionicIcon: 'alert-circle-outline'
    }*/
  ];

  // Inject HistoryHelperService in the app.components.ts so its available app-wide
  constructor(
    public translate: TranslateService,
    public historyHelper: HistoryHelperService,
    private router: Router,
    public authenticator: AuthenticatorService,
    private api: APIService,
    private modeService: MaintenanceModeService
  ) {
    this.getLoggedInUser();
    this.initializeApp();
    this.setLanguage();
  }

  async getLoggedInUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      this.loggedIn.next(user);
      const u = user.attributes.email;
      this.maintainer =
        u === "cmatthewcoatney@gmail.com" || u === "nico@nzeo.org";
    } catch (error) {
      console.log(error);
    }
  }

  accountEmail(): Observable<string> {
    const email = this.loggedIn?.value?.attributes?.email;

    if (!email) return of("");
    return of(email);
  }

  async initializeApp() {
    try {
      await SplashScreen.hide();

      // AWS Auth uses same page currently but when using seperate pages do ...
      // if(this.loggedIn !== 'undefined') this.authUrl = 'auth/signup'
    } catch (err) {
      console.log("This is normal in a browser", err);
    }
  }

  ngOnInit() {
    const ctx = this;
    // console.log(`Production = ${environment.production}`);
    this.api.GetAppState(this.appid).then((state) => {
      if (state) {
        // set initial state
        this.modeService.maintenanceMode.next(state?.maintenanceMode);
        this.shouldCreate = false;
      }
    });
    if (this.shouldCreate) {

      /* subscribe to new State being created */
      this.subscription = this.api
        .OnCreateAppStateListener()
        .subscribe((event: any) => {
          const newState = event.value.data;
        });
    } else {

      this.subscription = this.api
        .OnUpdateAppStateListener()
        .subscribe((event: any) => {
          const newState = event.value.data;
        });
    }
  }

  async createAppState() {
    
    const newState = {
      id: this.appid,
      maintenanceMode:  !this.modeService.maintenanceMode.value,
    };

    let result = null;
    if (this.shouldCreate) {
      result = await this.api.CreateAppState(newState);
      this.shouldCreate = false;
    } else {
      result = await this.api.UpdateAppState(newState);
    }

    const mode = result.maintenanceMode;
    
    this.modeService.maintenanceMode.next(mode);
    if (this.modeService.maintenanceMode.value) {
      this.router.navigateByUrl("/maintenancemode");
    }
  }

  toggleMaintenanceMode() {
    this.createAppState();
  }

  // think this is defunct as authenticator calls internal function?
  signout() {
    this.authenticator.signOut({});
    this.router.navigateByUrl("auth/login");
  }

  public setLanguage(): void {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang("en");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use("en");

    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });
  }

  public openTutorial(): void {
    // save key to mark the walkthrough as NOT visited because the user wants to check it out
    Preferences.set({
      key: "visitedWalkthrough",
      value: "false",
    });
    this.router.navigateByUrl("walkthrough");
  }

  // NOTE: Ionic only calls ngOnDestroy if the page was popped (ex: when navigating back)
  // Since ngOnDestroy might not fire when you navigate from the current page, use ionViewWillLeave to cleanup Subscriptions
  ionViewWillLeave(): void {
    this.subscription.unsubscribe();
  }
}
