import { Component, ViewChild, ViewContainerRef } from "@angular/core";
import { FieldWrapper } from "@ngx-formly/core";

@Component({
  selector: "app-formly-wrapper-header",
  template: `
   <span> {{ to.label }}</span>

    <ng-container #fieldComponent></ng-container>
  `,
})
export class HeaderWrapperComponent extends FieldWrapper {
  @ViewChild("fieldComponent", { read: ViewContainerRef })
  fieldComponent: ViewContainerRef;
}
