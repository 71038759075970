/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";


export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type __SubscriptionContainer = {
  onCreateAppState: OnCreateAppStateSubscription;
  onUpdateAppState: OnUpdateAppStateSubscription;
  onDeleteAppState: OnDeleteAppStateSubscription;
  onCreateBlog: OnCreateBlogSubscription;
  onUpdateBlog: OnUpdateBlogSubscription;
  onDeleteBlog: OnDeleteBlogSubscription;
  onCreatePost: OnCreatePostSubscription;
  onUpdatePost: OnUpdatePostSubscription;
  onDeletePost: OnDeletePostSubscription;
  onCreateComment: OnCreateCommentSubscription;
  onUpdateComment: OnUpdateCommentSubscription;
  onDeleteComment: OnDeleteCommentSubscription;
  onCreateEnergyCalculationRequest: OnCreateEnergyCalculationRequestSubscription;
  onUpdateEnergyCalculationRequest: OnUpdateEnergyCalculationRequestSubscription;
  onDeleteEnergyCalculationRequest: OnDeleteEnergyCalculationRequestSubscription;
};

export type CreateAppStateInput = {
  maintenanceMode?: boolean | null;
  id?: string | null;
};

export type ModelAppStateConditionInput = {
  maintenanceMode?: ModelBooleanInput | null;
  and?: Array<ModelAppStateConditionInput | null> | null;
  or?: Array<ModelAppStateConditionInput | null> | null;
  not?: ModelAppStateConditionInput | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type AppState = {
  __typename: "AppState";
  maintenanceMode?: boolean | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAppStateInput = {
  maintenanceMode?: boolean | null;
  id: string;
};

export type DeleteAppStateInput = {
  id: string;
};

export type CreateBlogInput = {
  name: string;
  id?: string | null;
};

export type ModelBlogConditionInput = {
  name?: ModelStringInput | null;
  and?: Array<ModelBlogConditionInput | null> | null;
  or?: Array<ModelBlogConditionInput | null> | null;
  not?: ModelBlogConditionInput | null;
};

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type Blog = {
  __typename: "Blog";
  name: string;
  posts?: ModelPostConnection | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type ModelPostConnection = {
  __typename: "ModelPostConnection";
  items: Array<Post | null>;
  nextToken?: string | null;
};

export type Post = {
  __typename: "Post";
  title: string;
  subtitle: string;
  author: string;
  date: string;
  img: string;
  html: string;
  blog?: Blog | null;
  comments?: ModelCommentConnection | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  blogPostsId?: string | null;
};

export type ModelCommentConnection = {
  __typename: "ModelCommentConnection";
  items: Array<Comment | null>;
  nextToken?: string | null;
};

export type Comment = {
  __typename: "Comment";
  post?: Post | null;
  content: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  postCommentsId?: string | null;
};

export type UpdateBlogInput = {
  name?: string | null;
  id: string;
};

export type DeleteBlogInput = {
  id: string;
};

export type CreatePostInput = {
  title: string;
  subtitle: string;
  author: string;
  date: string;
  img: string;
  html: string;
  id?: string | null;
  blogPostsId?: string | null;
};

export type ModelPostConditionInput = {
  title?: ModelStringInput | null;
  subtitle?: ModelStringInput | null;
  author?: ModelStringInput | null;
  date?: ModelStringInput | null;
  img?: ModelStringInput | null;
  html?: ModelStringInput | null;
  and?: Array<ModelPostConditionInput | null> | null;
  or?: Array<ModelPostConditionInput | null> | null;
  not?: ModelPostConditionInput | null;
  blogPostsId?: ModelIDInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdatePostInput = {
  title?: string | null;
  subtitle?: string | null;
  author?: string | null;
  date?: string | null;
  img?: string | null;
  html?: string | null;
  id: string;
  blogPostsId?: string | null;
};

export type DeletePostInput = {
  id: string;
};

export type CreateCommentInput = {
  content: string;
  id?: string | null;
  postCommentsId?: string | null;
};

export type ModelCommentConditionInput = {
  content?: ModelStringInput | null;
  and?: Array<ModelCommentConditionInput | null> | null;
  or?: Array<ModelCommentConditionInput | null> | null;
  not?: ModelCommentConditionInput | null;
  postCommentsId?: ModelIDInput | null;
};

export type UpdateCommentInput = {
  content?: string | null;
  id: string;
  postCommentsId?: string | null;
};

export type DeleteCommentInput = {
  id: string;
};

export type CreateEnergyCalculationRequestInput = {
  id?: string | null;
  owner: string;
  homeCount?: number | null;
  DOEIDS?: Array<string | null> | null;
  name: string;
  email: string;
  address: string;
  userType: string;
  companyName?: string | null;
  kwhr: number;
  sqft: number;
  useTherms: boolean;
  useCCF: boolean;
  useBTU: boolean;
  monthlyCost: number;
  annualCost: number;
  annualKwhr: number;
  hasSolar: boolean;
  hasNatGas: boolean;
  responseUserECI: number;
  responseUserEUI: number;
  responseAvgECI: number;
  responseAvgEUI: number;
  chartUrl: string;
  acceptedTerms: string;
  acceptedPolicy: string;
  accepted?: boolean | null;
  region: string;
  housingUnit: string;
  renterOccupied: boolean;
  numberStories: string;
  homeBuildYear: number;
  solarSize: number;
  hasBattery: boolean;
  batterySize: number;
  HVACSystem: string;
  originalHVAC: boolean;
  waterHeater: string;
  originalWaterHeater: boolean;
  programmableThermostat: boolean;
  dualPaneWindows: boolean;
  EVChargingSystem: boolean;
  primaryEnergyServiceProvider: string;
  primaryEnergySource: string;
  primaryServiceAgreementId: string;
  primaryMeterNumber: string;
  primaryRateSchedule: string;
  secondaryEnergyServiceProvider: string;
  secondaryEnergySource: string;
  secondaryServiceAgreementId: string;
  secondaryMeterNumber: string;
  secondaryRateSchedule: string;
  headerOne: string;
  headerTwo: string;
  headerThree: string;
  month1Electric: string;
  month1Gas: string;
  month1ElectricKWH: number;
  month1GasKWH: number;
  month1ElectricBill: number;
  month1GasBill: number;
  month2Electric: string;
  month2Gas: string;
  month2ElectricKWH: number;
  month2GasKWH: number;
  month2ElectricBill: number;
  month2GasBill: number;
  month3Electric: string;
  month3Gas: string;
  month3ElectricKWH: number;
  month3GasKWH: number;
  month3ElectricBill: number;
  month3GasBill: number;
  month4Electric: string;
  month4Gas: string;
  month4ElectricKWH: number;
  month4GasKWH: number;
  month4ElectricBill: number;
  month4GasBill: number;
  month5Electric: string;
  month5Gas: string;
  month5ElectricKWH: number;
  month5GasKWH: number;
  month5ElectricBill: number;
  month5GasBill: number;
  month6Electric: string;
  month6Gas: string;
  month6ElectricKWH: number;
  month6GasKWH: number;
  month6ElectricBill: number;
  month6GasBill: number;
  month7Electric: string;
  month7Gas: string;
  month7ElectricKWH: number;
  month7GasKWH: number;
  month7ElectricBill: number;
  month7GasBill: number;
  month8Electric: string;
  month8Gas: string;
  month8ElectricKWH: number;
  month8GasKWH: number;
  month8ElectricBill: number;
  month8GasBill: number;
  month9Electric: string;
  month9Gas: string;
  month9ElectricKWH: number;
  month9GasKWH: number;
  month9ElectricBill: number;
  month9GasBill: number;
  month10Electric: string;
  month10Gas: string;
  month10ElectricKWH: number;
  month10GasKWH: number;
  month10ElectricBill: number;
  month10GasBill: number;
  month11Electric: string;
  month11Gas: string;
  month11ElectricKWH: number;
  month11GasKWH: number;
  month11ElectricBill: number;
  month11GasBill: number;
  month12Electric: string;
  month12Gas: string;
  month12ElectricKWH: number;
  month12GasKWH: number;
  month12ElectricBill: number;
  month12GasBill: number;
};

export type ModelEnergyCalculationRequestConditionInput = {
  owner?: ModelIDInput | null;
  homeCount?: ModelFloatInput | null;
  DOEIDS?: ModelStringInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  address?: ModelStringInput | null;
  userType?: ModelStringInput | null;
  companyName?: ModelStringInput | null;
  kwhr?: ModelFloatInput | null;
  sqft?: ModelIntInput | null;
  useTherms?: ModelBooleanInput | null;
  useCCF?: ModelBooleanInput | null;
  useBTU?: ModelBooleanInput | null;
  monthlyCost?: ModelFloatInput | null;
  annualCost?: ModelFloatInput | null;
  annualKwhr?: ModelFloatInput | null;
  hasSolar?: ModelBooleanInput | null;
  hasNatGas?: ModelBooleanInput | null;
  responseUserECI?: ModelFloatInput | null;
  responseUserEUI?: ModelFloatInput | null;
  responseAvgECI?: ModelFloatInput | null;
  responseAvgEUI?: ModelFloatInput | null;
  chartUrl?: ModelStringInput | null;
  acceptedTerms?: ModelStringInput | null;
  acceptedPolicy?: ModelStringInput | null;
  accepted?: ModelBooleanInput | null;
  region?: ModelStringInput | null;
  housingUnit?: ModelStringInput | null;
  renterOccupied?: ModelBooleanInput | null;
  numberStories?: ModelStringInput | null;
  homeBuildYear?: ModelIntInput | null;
  solarSize?: ModelFloatInput | null;
  hasBattery?: ModelBooleanInput | null;
  batterySize?: ModelFloatInput | null;
  HVACSystem?: ModelStringInput | null;
  originalHVAC?: ModelBooleanInput | null;
  waterHeater?: ModelStringInput | null;
  originalWaterHeater?: ModelBooleanInput | null;
  programmableThermostat?: ModelBooleanInput | null;
  dualPaneWindows?: ModelBooleanInput | null;
  EVChargingSystem?: ModelBooleanInput | null;
  primaryEnergyServiceProvider?: ModelStringInput | null;
  primaryEnergySource?: ModelStringInput | null;
  primaryServiceAgreementId?: ModelStringInput | null;
  primaryMeterNumber?: ModelStringInput | null;
  primaryRateSchedule?: ModelStringInput | null;
  secondaryEnergyServiceProvider?: ModelStringInput | null;
  secondaryEnergySource?: ModelStringInput | null;
  secondaryServiceAgreementId?: ModelStringInput | null;
  secondaryMeterNumber?: ModelStringInput | null;
  secondaryRateSchedule?: ModelStringInput | null;
  headerOne?: ModelStringInput | null;
  headerTwo?: ModelStringInput | null;
  headerThree?: ModelStringInput | null;
  month1Electric?: ModelStringInput | null;
  month1Gas?: ModelStringInput | null;
  month1ElectricKWH?: ModelFloatInput | null;
  month1GasKWH?: ModelFloatInput | null;
  month1ElectricBill?: ModelFloatInput | null;
  month1GasBill?: ModelFloatInput | null;
  month2Electric?: ModelStringInput | null;
  month2Gas?: ModelStringInput | null;
  month2ElectricKWH?: ModelFloatInput | null;
  month2GasKWH?: ModelFloatInput | null;
  month2ElectricBill?: ModelFloatInput | null;
  month2GasBill?: ModelFloatInput | null;
  month3Electric?: ModelStringInput | null;
  month3Gas?: ModelStringInput | null;
  month3ElectricKWH?: ModelFloatInput | null;
  month3GasKWH?: ModelFloatInput | null;
  month3ElectricBill?: ModelFloatInput | null;
  month3GasBill?: ModelFloatInput | null;
  month4Electric?: ModelStringInput | null;
  month4Gas?: ModelStringInput | null;
  month4ElectricKWH?: ModelFloatInput | null;
  month4GasKWH?: ModelFloatInput | null;
  month4ElectricBill?: ModelFloatInput | null;
  month4GasBill?: ModelFloatInput | null;
  month5Electric?: ModelStringInput | null;
  month5Gas?: ModelStringInput | null;
  month5ElectricKWH?: ModelFloatInput | null;
  month5GasKWH?: ModelFloatInput | null;
  month5ElectricBill?: ModelFloatInput | null;
  month5GasBill?: ModelFloatInput | null;
  month6Electric?: ModelStringInput | null;
  month6Gas?: ModelStringInput | null;
  month6ElectricKWH?: ModelFloatInput | null;
  month6GasKWH?: ModelFloatInput | null;
  month6ElectricBill?: ModelFloatInput | null;
  month6GasBill?: ModelFloatInput | null;
  month7Electric?: ModelStringInput | null;
  month7Gas?: ModelStringInput | null;
  month7ElectricKWH?: ModelFloatInput | null;
  month7GasKWH?: ModelFloatInput | null;
  month7ElectricBill?: ModelFloatInput | null;
  month7GasBill?: ModelFloatInput | null;
  month8Electric?: ModelStringInput | null;
  month8Gas?: ModelStringInput | null;
  month8ElectricKWH?: ModelFloatInput | null;
  month8GasKWH?: ModelFloatInput | null;
  month8ElectricBill?: ModelFloatInput | null;
  month8GasBill?: ModelFloatInput | null;
  month9Electric?: ModelStringInput | null;
  month9Gas?: ModelStringInput | null;
  month9ElectricKWH?: ModelFloatInput | null;
  month9GasKWH?: ModelFloatInput | null;
  month9ElectricBill?: ModelFloatInput | null;
  month9GasBill?: ModelFloatInput | null;
  month10Electric?: ModelStringInput | null;
  month10Gas?: ModelStringInput | null;
  month10ElectricKWH?: ModelFloatInput | null;
  month10GasKWH?: ModelFloatInput | null;
  month10ElectricBill?: ModelFloatInput | null;
  month10GasBill?: ModelFloatInput | null;
  month11Electric?: ModelStringInput | null;
  month11Gas?: ModelStringInput | null;
  month11ElectricKWH?: ModelFloatInput | null;
  month11GasKWH?: ModelFloatInput | null;
  month11ElectricBill?: ModelFloatInput | null;
  month11GasBill?: ModelFloatInput | null;
  month12Electric?: ModelStringInput | null;
  month12Gas?: ModelStringInput | null;
  month12ElectricKWH?: ModelFloatInput | null;
  month12GasKWH?: ModelFloatInput | null;
  month12ElectricBill?: ModelFloatInput | null;
  month12GasBill?: ModelFloatInput | null;
  and?: Array<ModelEnergyCalculationRequestConditionInput | null> | null;
  or?: Array<ModelEnergyCalculationRequestConditionInput | null> | null;
  not?: ModelEnergyCalculationRequestConditionInput | null;
};

export type ModelFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type EnergyCalculationRequest = {
  __typename: "EnergyCalculationRequest";
  id: string;
  owner: string;
  homeCount?: number | null;
  DOEIDS?: Array<string | null> | null;
  name: string;
  email: string;
  address: string;
  userType: string;
  companyName?: string | null;
  kwhr: number;
  sqft: number;
  useTherms: boolean;
  useCCF: boolean;
  useBTU: boolean;
  monthlyCost: number;
  annualCost: number;
  annualKwhr: number;
  hasSolar: boolean;
  hasNatGas: boolean;
  responseUserECI: number;
  responseUserEUI: number;
  responseAvgECI: number;
  responseAvgEUI: number;
  chartUrl: string;
  acceptedTerms: string;
  acceptedPolicy: string;
  accepted?: boolean | null;
  region: string;
  housingUnit: string;
  renterOccupied: boolean;
  numberStories: string;
  homeBuildYear: number;
  solarSize: number;
  hasBattery: boolean;
  batterySize: number;
  HVACSystem: string;
  originalHVAC: boolean;
  waterHeater: string;
  originalWaterHeater: boolean;
  programmableThermostat: boolean;
  dualPaneWindows: boolean;
  EVChargingSystem: boolean;
  primaryEnergyServiceProvider: string;
  primaryEnergySource: string;
  primaryServiceAgreementId: string;
  primaryMeterNumber: string;
  primaryRateSchedule: string;
  secondaryEnergyServiceProvider: string;
  secondaryEnergySource: string;
  secondaryServiceAgreementId: string;
  secondaryMeterNumber: string;
  secondaryRateSchedule: string;
  headerOne: string;
  headerTwo: string;
  headerThree: string;
  month1Electric: string;
  month1Gas: string;
  month1ElectricKWH: number;
  month1GasKWH: number;
  month1ElectricBill: number;
  month1GasBill: number;
  month2Electric: string;
  month2Gas: string;
  month2ElectricKWH: number;
  month2GasKWH: number;
  month2ElectricBill: number;
  month2GasBill: number;
  month3Electric: string;
  month3Gas: string;
  month3ElectricKWH: number;
  month3GasKWH: number;
  month3ElectricBill: number;
  month3GasBill: number;
  month4Electric: string;
  month4Gas: string;
  month4ElectricKWH: number;
  month4GasKWH: number;
  month4ElectricBill: number;
  month4GasBill: number;
  month5Electric: string;
  month5Gas: string;
  month5ElectricKWH: number;
  month5GasKWH: number;
  month5ElectricBill: number;
  month5GasBill: number;
  month6Electric: string;
  month6Gas: string;
  month6ElectricKWH: number;
  month6GasKWH: number;
  month6ElectricBill: number;
  month6GasBill: number;
  month7Electric: string;
  month7Gas: string;
  month7ElectricKWH: number;
  month7GasKWH: number;
  month7ElectricBill: number;
  month7GasBill: number;
  month8Electric: string;
  month8Gas: string;
  month8ElectricKWH: number;
  month8GasKWH: number;
  month8ElectricBill: number;
  month8GasBill: number;
  month9Electric: string;
  month9Gas: string;
  month9ElectricKWH: number;
  month9GasKWH: number;
  month9ElectricBill: number;
  month9GasBill: number;
  month10Electric: string;
  month10Gas: string;
  month10ElectricKWH: number;
  month10GasKWH: number;
  month10ElectricBill: number;
  month10GasBill: number;
  month11Electric: string;
  month11Gas: string;
  month11ElectricKWH: number;
  month11GasKWH: number;
  month11ElectricBill: number;
  month11GasBill: number;
  month12Electric: string;
  month12Gas: string;
  month12ElectricKWH: number;
  month12GasKWH: number;
  month12ElectricBill: number;
  month12GasBill: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEnergyCalculationRequestInput = {
  id: string;
  owner?: string | null;
  homeCount?: number | null;
  DOEIDS?: Array<string | null> | null;
  name?: string | null;
  email?: string | null;
  address?: string | null;
  userType?: string | null;
  companyName?: string | null;
  kwhr?: number | null;
  sqft?: number | null;
  useTherms?: boolean | null;
  useCCF?: boolean | null;
  useBTU?: boolean | null;
  monthlyCost?: number | null;
  annualCost?: number | null;
  annualKwhr?: number | null;
  hasSolar?: boolean | null;
  hasNatGas?: boolean | null;
  responseUserECI?: number | null;
  responseUserEUI?: number | null;
  responseAvgECI?: number | null;
  responseAvgEUI?: number | null;
  chartUrl?: string | null;
  acceptedTerms?: string | null;
  acceptedPolicy?: string | null;
  accepted?: boolean | null;
  region?: string | null;
  housingUnit?: string | null;
  renterOccupied?: boolean | null;
  numberStories?: string | null;
  homeBuildYear?: number | null;
  solarSize?: number | null;
  hasBattery?: boolean | null;
  batterySize?: number | null;
  HVACSystem?: string | null;
  originalHVAC?: boolean | null;
  waterHeater?: string | null;
  originalWaterHeater?: boolean | null;
  programmableThermostat?: boolean | null;
  dualPaneWindows?: boolean | null;
  EVChargingSystem?: boolean | null;
  primaryEnergyServiceProvider?: string | null;
  primaryEnergySource?: string | null;
  primaryServiceAgreementId?: string | null;
  primaryMeterNumber?: string | null;
  primaryRateSchedule?: string | null;
  secondaryEnergyServiceProvider?: string | null;
  secondaryEnergySource?: string | null;
  secondaryServiceAgreementId?: string | null;
  secondaryMeterNumber?: string | null;
  secondaryRateSchedule?: string | null;
  headerOne?: string | null;
  headerTwo?: string | null;
  headerThree?: string | null;
  month1Electric?: string | null;
  month1Gas?: string | null;
  month1ElectricKWH?: number | null;
  month1GasKWH?: number | null;
  month1ElectricBill?: number | null;
  month1GasBill?: number | null;
  month2Electric?: string | null;
  month2Gas?: string | null;
  month2ElectricKWH?: number | null;
  month2GasKWH?: number | null;
  month2ElectricBill?: number | null;
  month2GasBill?: number | null;
  month3Electric?: string | null;
  month3Gas?: string | null;
  month3ElectricKWH?: number | null;
  month3GasKWH?: number | null;
  month3ElectricBill?: number | null;
  month3GasBill?: number | null;
  month4Electric?: string | null;
  month4Gas?: string | null;
  month4ElectricKWH?: number | null;
  month4GasKWH?: number | null;
  month4ElectricBill?: number | null;
  month4GasBill?: number | null;
  month5Electric?: string | null;
  month5Gas?: string | null;
  month5ElectricKWH?: number | null;
  month5GasKWH?: number | null;
  month5ElectricBill?: number | null;
  month5GasBill?: number | null;
  month6Electric?: string | null;
  month6Gas?: string | null;
  month6ElectricKWH?: number | null;
  month6GasKWH?: number | null;
  month6ElectricBill?: number | null;
  month6GasBill?: number | null;
  month7Electric?: string | null;
  month7Gas?: string | null;
  month7ElectricKWH?: number | null;
  month7GasKWH?: number | null;
  month7ElectricBill?: number | null;
  month7GasBill?: number | null;
  month8Electric?: string | null;
  month8Gas?: string | null;
  month8ElectricKWH?: number | null;
  month8GasKWH?: number | null;
  month8ElectricBill?: number | null;
  month8GasBill?: number | null;
  month9Electric?: string | null;
  month9Gas?: string | null;
  month9ElectricKWH?: number | null;
  month9GasKWH?: number | null;
  month9ElectricBill?: number | null;
  month9GasBill?: number | null;
  month10Electric?: string | null;
  month10Gas?: string | null;
  month10ElectricKWH?: number | null;
  month10GasKWH?: number | null;
  month10ElectricBill?: number | null;
  month10GasBill?: number | null;
  month11Electric?: string | null;
  month11Gas?: string | null;
  month11ElectricKWH?: number | null;
  month11GasKWH?: number | null;
  month11ElectricBill?: number | null;
  month11GasBill?: number | null;
  month12Electric?: string | null;
  month12Gas?: string | null;
  month12ElectricKWH?: number | null;
  month12GasKWH?: number | null;
  month12ElectricBill?: number | null;
  month12GasBill?: number | null;
};

export type DeleteEnergyCalculationRequestInput = {
  id: string;
};

export type ModelAppStateFilterInput = {
  maintenanceMode?: ModelBooleanInput | null;
  and?: Array<ModelAppStateFilterInput | null> | null;
  or?: Array<ModelAppStateFilterInput | null> | null;
  not?: ModelAppStateFilterInput | null;
};

export type ModelAppStateConnection = {
  __typename: "ModelAppStateConnection";
  items: Array<AppState | null>;
  nextToken?: string | null;
};

export type ModelBlogFilterInput = {
  name?: ModelStringInput | null;
  and?: Array<ModelBlogFilterInput | null> | null;
  or?: Array<ModelBlogFilterInput | null> | null;
  not?: ModelBlogFilterInput | null;
};

export type ModelBlogConnection = {
  __typename: "ModelBlogConnection";
  items: Array<Blog | null>;
  nextToken?: string | null;
};

export type ModelPostFilterInput = {
  title?: ModelStringInput | null;
  subtitle?: ModelStringInput | null;
  author?: ModelStringInput | null;
  date?: ModelStringInput | null;
  img?: ModelStringInput | null;
  html?: ModelStringInput | null;
  and?: Array<ModelPostFilterInput | null> | null;
  or?: Array<ModelPostFilterInput | null> | null;
  not?: ModelPostFilterInput | null;
  blogPostsId?: ModelIDInput | null;
};

export type ModelCommentFilterInput = {
  content?: ModelStringInput | null;
  and?: Array<ModelCommentFilterInput | null> | null;
  or?: Array<ModelCommentFilterInput | null> | null;
  not?: ModelCommentFilterInput | null;
  postCommentsId?: ModelIDInput | null;
};

export type ModelEnergyCalculationRequestFilterInput = {
  id?: ModelIDInput | null;
  owner?: ModelIDInput | null;
  homeCount?: ModelFloatInput | null;
  DOEIDS?: ModelStringInput | null;
  name?: ModelStringInput | null;
  email?: ModelStringInput | null;
  address?: ModelStringInput | null;
  userType?: ModelStringInput | null;
  companyName?: ModelStringInput | null;
  kwhr?: ModelFloatInput | null;
  sqft?: ModelIntInput | null;
  useTherms?: ModelBooleanInput | null;
  useCCF?: ModelBooleanInput | null;
  useBTU?: ModelBooleanInput | null;
  monthlyCost?: ModelFloatInput | null;
  annualCost?: ModelFloatInput | null;
  annualKwhr?: ModelFloatInput | null;
  hasSolar?: ModelBooleanInput | null;
  hasNatGas?: ModelBooleanInput | null;
  responseUserECI?: ModelFloatInput | null;
  responseUserEUI?: ModelFloatInput | null;
  responseAvgECI?: ModelFloatInput | null;
  responseAvgEUI?: ModelFloatInput | null;
  chartUrl?: ModelStringInput | null;
  acceptedTerms?: ModelStringInput | null;
  acceptedPolicy?: ModelStringInput | null;
  accepted?: ModelBooleanInput | null;
  region?: ModelStringInput | null;
  housingUnit?: ModelStringInput | null;
  renterOccupied?: ModelBooleanInput | null;
  numberStories?: ModelStringInput | null;
  homeBuildYear?: ModelIntInput | null;
  solarSize?: ModelFloatInput | null;
  hasBattery?: ModelBooleanInput | null;
  batterySize?: ModelFloatInput | null;
  HVACSystem?: ModelStringInput | null;
  originalHVAC?: ModelBooleanInput | null;
  waterHeater?: ModelStringInput | null;
  originalWaterHeater?: ModelBooleanInput | null;
  programmableThermostat?: ModelBooleanInput | null;
  dualPaneWindows?: ModelBooleanInput | null;
  EVChargingSystem?: ModelBooleanInput | null;
  primaryEnergyServiceProvider?: ModelStringInput | null;
  primaryEnergySource?: ModelStringInput | null;
  primaryServiceAgreementId?: ModelStringInput | null;
  primaryMeterNumber?: ModelStringInput | null;
  primaryRateSchedule?: ModelStringInput | null;
  secondaryEnergyServiceProvider?: ModelStringInput | null;
  secondaryEnergySource?: ModelStringInput | null;
  secondaryServiceAgreementId?: ModelStringInput | null;
  secondaryMeterNumber?: ModelStringInput | null;
  secondaryRateSchedule?: ModelStringInput | null;
  headerOne?: ModelStringInput | null;
  headerTwo?: ModelStringInput | null;
  headerThree?: ModelStringInput | null;
  month1Electric?: ModelStringInput | null;
  month1Gas?: ModelStringInput | null;
  month1ElectricKWH?: ModelFloatInput | null;
  month1GasKWH?: ModelFloatInput | null;
  month1ElectricBill?: ModelFloatInput | null;
  month1GasBill?: ModelFloatInput | null;
  month2Electric?: ModelStringInput | null;
  month2Gas?: ModelStringInput | null;
  month2ElectricKWH?: ModelFloatInput | null;
  month2GasKWH?: ModelFloatInput | null;
  month2ElectricBill?: ModelFloatInput | null;
  month2GasBill?: ModelFloatInput | null;
  month3Electric?: ModelStringInput | null;
  month3Gas?: ModelStringInput | null;
  month3ElectricKWH?: ModelFloatInput | null;
  month3GasKWH?: ModelFloatInput | null;
  month3ElectricBill?: ModelFloatInput | null;
  month3GasBill?: ModelFloatInput | null;
  month4Electric?: ModelStringInput | null;
  month4Gas?: ModelStringInput | null;
  month4ElectricKWH?: ModelFloatInput | null;
  month4GasKWH?: ModelFloatInput | null;
  month4ElectricBill?: ModelFloatInput | null;
  month4GasBill?: ModelFloatInput | null;
  month5Electric?: ModelStringInput | null;
  month5Gas?: ModelStringInput | null;
  month5ElectricKWH?: ModelFloatInput | null;
  month5GasKWH?: ModelFloatInput | null;
  month5ElectricBill?: ModelFloatInput | null;
  month5GasBill?: ModelFloatInput | null;
  month6Electric?: ModelStringInput | null;
  month6Gas?: ModelStringInput | null;
  month6ElectricKWH?: ModelFloatInput | null;
  month6GasKWH?: ModelFloatInput | null;
  month6ElectricBill?: ModelFloatInput | null;
  month6GasBill?: ModelFloatInput | null;
  month7Electric?: ModelStringInput | null;
  month7Gas?: ModelStringInput | null;
  month7ElectricKWH?: ModelFloatInput | null;
  month7GasKWH?: ModelFloatInput | null;
  month7ElectricBill?: ModelFloatInput | null;
  month7GasBill?: ModelFloatInput | null;
  month8Electric?: ModelStringInput | null;
  month8Gas?: ModelStringInput | null;
  month8ElectricKWH?: ModelFloatInput | null;
  month8GasKWH?: ModelFloatInput | null;
  month8ElectricBill?: ModelFloatInput | null;
  month8GasBill?: ModelFloatInput | null;
  month9Electric?: ModelStringInput | null;
  month9Gas?: ModelStringInput | null;
  month9ElectricKWH?: ModelFloatInput | null;
  month9GasKWH?: ModelFloatInput | null;
  month9ElectricBill?: ModelFloatInput | null;
  month9GasBill?: ModelFloatInput | null;
  month10Electric?: ModelStringInput | null;
  month10Gas?: ModelStringInput | null;
  month10ElectricKWH?: ModelFloatInput | null;
  month10GasKWH?: ModelFloatInput | null;
  month10ElectricBill?: ModelFloatInput | null;
  month10GasBill?: ModelFloatInput | null;
  month11Electric?: ModelStringInput | null;
  month11Gas?: ModelStringInput | null;
  month11ElectricKWH?: ModelFloatInput | null;
  month11GasKWH?: ModelFloatInput | null;
  month11ElectricBill?: ModelFloatInput | null;
  month11GasBill?: ModelFloatInput | null;
  month12Electric?: ModelStringInput | null;
  month12Gas?: ModelStringInput | null;
  month12ElectricKWH?: ModelFloatInput | null;
  month12GasKWH?: ModelFloatInput | null;
  month12ElectricBill?: ModelFloatInput | null;
  month12GasBill?: ModelFloatInput | null;
  and?: Array<ModelEnergyCalculationRequestFilterInput | null> | null;
  or?: Array<ModelEnergyCalculationRequestFilterInput | null> | null;
  not?: ModelEnergyCalculationRequestFilterInput | null;
};

export type ModelEnergyCalculationRequestConnection = {
  __typename: "ModelEnergyCalculationRequestConnection";
  items: Array<EnergyCalculationRequest | null>;
  nextToken?: string | null;
};

export type ModelSubscriptionAppStateFilterInput = {
  maintenanceMode?: ModelSubscriptionBooleanInput | null;
  and?: Array<ModelSubscriptionAppStateFilterInput | null> | null;
  or?: Array<ModelSubscriptionAppStateFilterInput | null> | null;
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
};

export type ModelSubscriptionBlogFilterInput = {
  name?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionBlogFilterInput | null> | null;
  or?: Array<ModelSubscriptionBlogFilterInput | null> | null;
};

export type ModelSubscriptionStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionPostFilterInput = {
  title?: ModelSubscriptionStringInput | null;
  subtitle?: ModelSubscriptionStringInput | null;
  author?: ModelSubscriptionStringInput | null;
  date?: ModelSubscriptionStringInput | null;
  img?: ModelSubscriptionStringInput | null;
  html?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionPostFilterInput | null> | null;
  or?: Array<ModelSubscriptionPostFilterInput | null> | null;
};

export type ModelSubscriptionCommentFilterInput = {
  content?: ModelSubscriptionStringInput | null;
  and?: Array<ModelSubscriptionCommentFilterInput | null> | null;
  or?: Array<ModelSubscriptionCommentFilterInput | null> | null;
};

export type ModelSubscriptionEnergyCalculationRequestFilterInput = {
  id?: ModelSubscriptionIDInput | null;
  owner?: ModelSubscriptionIDInput | null;
  homeCount?: ModelSubscriptionFloatInput | null;
  DOEIDS?: ModelSubscriptionStringInput | null;
  name?: ModelSubscriptionStringInput | null;
  email?: ModelSubscriptionStringInput | null;
  address?: ModelSubscriptionStringInput | null;
  userType?: ModelSubscriptionStringInput | null;
  companyName?: ModelSubscriptionStringInput | null;
  kwhr?: ModelSubscriptionFloatInput | null;
  sqft?: ModelSubscriptionIntInput | null;
  useTherms?: ModelSubscriptionBooleanInput | null;
  useCCF?: ModelSubscriptionBooleanInput | null;
  useBTU?: ModelSubscriptionBooleanInput | null;
  monthlyCost?: ModelSubscriptionFloatInput | null;
  annualCost?: ModelSubscriptionFloatInput | null;
  annualKwhr?: ModelSubscriptionFloatInput | null;
  hasSolar?: ModelSubscriptionBooleanInput | null;
  hasNatGas?: ModelSubscriptionBooleanInput | null;
  responseUserECI?: ModelSubscriptionFloatInput | null;
  responseUserEUI?: ModelSubscriptionFloatInput | null;
  responseAvgECI?: ModelSubscriptionFloatInput | null;
  responseAvgEUI?: ModelSubscriptionFloatInput | null;
  chartUrl?: ModelSubscriptionStringInput | null;
  acceptedTerms?: ModelSubscriptionStringInput | null;
  acceptedPolicy?: ModelSubscriptionStringInput | null;
  accepted?: ModelSubscriptionBooleanInput | null;
  region?: ModelSubscriptionStringInput | null;
  housingUnit?: ModelSubscriptionStringInput | null;
  renterOccupied?: ModelSubscriptionBooleanInput | null;
  numberStories?: ModelSubscriptionStringInput | null;
  homeBuildYear?: ModelSubscriptionIntInput | null;
  solarSize?: ModelSubscriptionFloatInput | null;
  hasBattery?: ModelSubscriptionBooleanInput | null;
  batterySize?: ModelSubscriptionFloatInput | null;
  HVACSystem?: ModelSubscriptionStringInput | null;
  originalHVAC?: ModelSubscriptionBooleanInput | null;
  waterHeater?: ModelSubscriptionStringInput | null;
  originalWaterHeater?: ModelSubscriptionBooleanInput | null;
  programmableThermostat?: ModelSubscriptionBooleanInput | null;
  dualPaneWindows?: ModelSubscriptionBooleanInput | null;
  EVChargingSystem?: ModelSubscriptionBooleanInput | null;
  primaryEnergyServiceProvider?: ModelSubscriptionStringInput | null;
  primaryEnergySource?: ModelSubscriptionStringInput | null;
  primaryServiceAgreementId?: ModelSubscriptionStringInput | null;
  primaryMeterNumber?: ModelSubscriptionStringInput | null;
  primaryRateSchedule?: ModelSubscriptionStringInput | null;
  secondaryEnergyServiceProvider?: ModelSubscriptionStringInput | null;
  secondaryEnergySource?: ModelSubscriptionStringInput | null;
  secondaryServiceAgreementId?: ModelSubscriptionStringInput | null;
  secondaryMeterNumber?: ModelSubscriptionStringInput | null;
  secondaryRateSchedule?: ModelSubscriptionStringInput | null;
  headerOne?: ModelSubscriptionStringInput | null;
  headerTwo?: ModelSubscriptionStringInput | null;
  headerThree?: ModelSubscriptionStringInput | null;
  month1Electric?: ModelSubscriptionStringInput | null;
  month1Gas?: ModelSubscriptionStringInput | null;
  month1ElectricKWH?: ModelSubscriptionFloatInput | null;
  month1GasKWH?: ModelSubscriptionFloatInput | null;
  month1ElectricBill?: ModelSubscriptionFloatInput | null;
  month1GasBill?: ModelSubscriptionFloatInput | null;
  month2Electric?: ModelSubscriptionStringInput | null;
  month2Gas?: ModelSubscriptionStringInput | null;
  month2ElectricKWH?: ModelSubscriptionFloatInput | null;
  month2GasKWH?: ModelSubscriptionFloatInput | null;
  month2ElectricBill?: ModelSubscriptionFloatInput | null;
  month2GasBill?: ModelSubscriptionFloatInput | null;
  month3Electric?: ModelSubscriptionStringInput | null;
  month3Gas?: ModelSubscriptionStringInput | null;
  month3ElectricKWH?: ModelSubscriptionFloatInput | null;
  month3GasKWH?: ModelSubscriptionFloatInput | null;
  month3ElectricBill?: ModelSubscriptionFloatInput | null;
  month3GasBill?: ModelSubscriptionFloatInput | null;
  month4Electric?: ModelSubscriptionStringInput | null;
  month4Gas?: ModelSubscriptionStringInput | null;
  month4ElectricKWH?: ModelSubscriptionFloatInput | null;
  month4GasKWH?: ModelSubscriptionFloatInput | null;
  month4ElectricBill?: ModelSubscriptionFloatInput | null;
  month4GasBill?: ModelSubscriptionFloatInput | null;
  month5Electric?: ModelSubscriptionStringInput | null;
  month5Gas?: ModelSubscriptionStringInput | null;
  month5ElectricKWH?: ModelSubscriptionFloatInput | null;
  month5GasKWH?: ModelSubscriptionFloatInput | null;
  month5ElectricBill?: ModelSubscriptionFloatInput | null;
  month5GasBill?: ModelSubscriptionFloatInput | null;
  month6Electric?: ModelSubscriptionStringInput | null;
  month6Gas?: ModelSubscriptionStringInput | null;
  month6ElectricKWH?: ModelSubscriptionFloatInput | null;
  month6GasKWH?: ModelSubscriptionFloatInput | null;
  month6ElectricBill?: ModelSubscriptionFloatInput | null;
  month6GasBill?: ModelSubscriptionFloatInput | null;
  month7Electric?: ModelSubscriptionStringInput | null;
  month7Gas?: ModelSubscriptionStringInput | null;
  month7ElectricKWH?: ModelSubscriptionFloatInput | null;
  month7GasKWH?: ModelSubscriptionFloatInput | null;
  month7ElectricBill?: ModelSubscriptionFloatInput | null;
  month7GasBill?: ModelSubscriptionFloatInput | null;
  month8Electric?: ModelSubscriptionStringInput | null;
  month8Gas?: ModelSubscriptionStringInput | null;
  month8ElectricKWH?: ModelSubscriptionFloatInput | null;
  month8GasKWH?: ModelSubscriptionFloatInput | null;
  month8ElectricBill?: ModelSubscriptionFloatInput | null;
  month8GasBill?: ModelSubscriptionFloatInput | null;
  month9Electric?: ModelSubscriptionStringInput | null;
  month9Gas?: ModelSubscriptionStringInput | null;
  month9ElectricKWH?: ModelSubscriptionFloatInput | null;
  month9GasKWH?: ModelSubscriptionFloatInput | null;
  month9ElectricBill?: ModelSubscriptionFloatInput | null;
  month9GasBill?: ModelSubscriptionFloatInput | null;
  month10Electric?: ModelSubscriptionStringInput | null;
  month10Gas?: ModelSubscriptionStringInput | null;
  month10ElectricKWH?: ModelSubscriptionFloatInput | null;
  month10GasKWH?: ModelSubscriptionFloatInput | null;
  month10ElectricBill?: ModelSubscriptionFloatInput | null;
  month10GasBill?: ModelSubscriptionFloatInput | null;
  month11Electric?: ModelSubscriptionStringInput | null;
  month11Gas?: ModelSubscriptionStringInput | null;
  month11ElectricKWH?: ModelSubscriptionFloatInput | null;
  month11GasKWH?: ModelSubscriptionFloatInput | null;
  month11ElectricBill?: ModelSubscriptionFloatInput | null;
  month11GasBill?: ModelSubscriptionFloatInput | null;
  month12Electric?: ModelSubscriptionStringInput | null;
  month12Gas?: ModelSubscriptionStringInput | null;
  month12ElectricKWH?: ModelSubscriptionFloatInput | null;
  month12GasKWH?: ModelSubscriptionFloatInput | null;
  month12ElectricBill?: ModelSubscriptionFloatInput | null;
  month12GasBill?: ModelSubscriptionFloatInput | null;
  and?: Array<ModelSubscriptionEnergyCalculationRequestFilterInput | null> | null;
  or?: Array<ModelSubscriptionEnergyCalculationRequestFilterInput | null> | null;
};

export type ModelSubscriptionIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  in?: Array<string | null> | null;
  notIn?: Array<string | null> | null;
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type ModelSubscriptionIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  in?: Array<number | null> | null;
  notIn?: Array<number | null> | null;
};

export type CreateAppStateMutation = {
  __typename: "AppState";
  maintenanceMode?: boolean | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAppStateMutation = {
  __typename: "AppState";
  maintenanceMode?: boolean | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAppStateMutation = {
  __typename: "AppState";
  maintenanceMode?: boolean | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateBlogMutation = {
  __typename: "Blog";
  name: string;
  posts?: {
    __typename: "ModelPostConnection";
    items: Array<{
      __typename: "Post";
      title: string;
      subtitle: string;
      author: string;
      date: string;
      img: string;
      html: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      blogPostsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateBlogMutation = {
  __typename: "Blog";
  name: string;
  posts?: {
    __typename: "ModelPostConnection";
    items: Array<{
      __typename: "Post";
      title: string;
      subtitle: string;
      author: string;
      date: string;
      img: string;
      html: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      blogPostsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteBlogMutation = {
  __typename: "Blog";
  name: string;
  posts?: {
    __typename: "ModelPostConnection";
    items: Array<{
      __typename: "Post";
      title: string;
      subtitle: string;
      author: string;
      date: string;
      img: string;
      html: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      blogPostsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type CreatePostMutation = {
  __typename: "Post";
  title: string;
  subtitle: string;
  author: string;
  date: string;
  img: string;
  html: string;
  blog?: {
    __typename: "Blog";
    name: string;
    posts?: {
      __typename: "ModelPostConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  comments?: {
    __typename: "ModelCommentConnection";
    items: Array<{
      __typename: "Comment";
      content: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      postCommentsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  blogPostsId?: string | null;
};

export type UpdatePostMutation = {
  __typename: "Post";
  title: string;
  subtitle: string;
  author: string;
  date: string;
  img: string;
  html: string;
  blog?: {
    __typename: "Blog";
    name: string;
    posts?: {
      __typename: "ModelPostConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  comments?: {
    __typename: "ModelCommentConnection";
    items: Array<{
      __typename: "Comment";
      content: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      postCommentsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  blogPostsId?: string | null;
};

export type DeletePostMutation = {
  __typename: "Post";
  title: string;
  subtitle: string;
  author: string;
  date: string;
  img: string;
  html: string;
  blog?: {
    __typename: "Blog";
    name: string;
    posts?: {
      __typename: "ModelPostConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  comments?: {
    __typename: "ModelCommentConnection";
    items: Array<{
      __typename: "Comment";
      content: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      postCommentsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  blogPostsId?: string | null;
};

export type CreateCommentMutation = {
  __typename: "Comment";
  post?: {
    __typename: "Post";
    title: string;
    subtitle: string;
    author: string;
    date: string;
    img: string;
    html: string;
    blog?: {
      __typename: "Blog";
      name: string;
      id: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    comments?: {
      __typename: "ModelCommentConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    blogPostsId?: string | null;
  } | null;
  content: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  postCommentsId?: string | null;
};

export type UpdateCommentMutation = {
  __typename: "Comment";
  post?: {
    __typename: "Post";
    title: string;
    subtitle: string;
    author: string;
    date: string;
    img: string;
    html: string;
    blog?: {
      __typename: "Blog";
      name: string;
      id: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    comments?: {
      __typename: "ModelCommentConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    blogPostsId?: string | null;
  } | null;
  content: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  postCommentsId?: string | null;
};

export type DeleteCommentMutation = {
  __typename: "Comment";
  post?: {
    __typename: "Post";
    title: string;
    subtitle: string;
    author: string;
    date: string;
    img: string;
    html: string;
    blog?: {
      __typename: "Blog";
      name: string;
      id: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    comments?: {
      __typename: "ModelCommentConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    blogPostsId?: string | null;
  } | null;
  content: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  postCommentsId?: string | null;
};

export type CreateEnergyCalculationRequestMutation = {
  __typename: "EnergyCalculationRequest";
  id: string;
  owner: string;
  homeCount?: number | null;
  DOEIDS?: Array<string | null> | null;
  name: string;
  email: string;
  address: string;
  userType: string;
  companyName?: string | null;
  kwhr: number;
  sqft: number;
  useTherms: boolean;
  useCCF: boolean;
  useBTU: boolean;
  monthlyCost: number;
  annualCost: number;
  annualKwhr: number;
  hasSolar: boolean;
  hasNatGas: boolean;
  responseUserECI: number;
  responseUserEUI: number;
  responseAvgECI: number;
  responseAvgEUI: number;
  chartUrl: string;
  acceptedTerms: string;
  acceptedPolicy: string;
  accepted?: boolean | null;
  region: string;
  housingUnit: string;
  renterOccupied: boolean;
  numberStories: string;
  homeBuildYear: number;
  solarSize: number;
  hasBattery: boolean;
  batterySize: number;
  HVACSystem: string;
  originalHVAC: boolean;
  waterHeater: string;
  originalWaterHeater: boolean;
  programmableThermostat: boolean;
  dualPaneWindows: boolean;
  EVChargingSystem: boolean;
  primaryEnergyServiceProvider: string;
  primaryEnergySource: string;
  primaryServiceAgreementId: string;
  primaryMeterNumber: string;
  primaryRateSchedule: string;
  secondaryEnergyServiceProvider: string;
  secondaryEnergySource: string;
  secondaryServiceAgreementId: string;
  secondaryMeterNumber: string;
  secondaryRateSchedule: string;
  headerOne: string;
  headerTwo: string;
  headerThree: string;
  month1Electric: string;
  month1Gas: string;
  month1ElectricKWH: number;
  month1GasKWH: number;
  month1ElectricBill: number;
  month1GasBill: number;
  month2Electric: string;
  month2Gas: string;
  month2ElectricKWH: number;
  month2GasKWH: number;
  month2ElectricBill: number;
  month2GasBill: number;
  month3Electric: string;
  month3Gas: string;
  month3ElectricKWH: number;
  month3GasKWH: number;
  month3ElectricBill: number;
  month3GasBill: number;
  month4Electric: string;
  month4Gas: string;
  month4ElectricKWH: number;
  month4GasKWH: number;
  month4ElectricBill: number;
  month4GasBill: number;
  month5Electric: string;
  month5Gas: string;
  month5ElectricKWH: number;
  month5GasKWH: number;
  month5ElectricBill: number;
  month5GasBill: number;
  month6Electric: string;
  month6Gas: string;
  month6ElectricKWH: number;
  month6GasKWH: number;
  month6ElectricBill: number;
  month6GasBill: number;
  month7Electric: string;
  month7Gas: string;
  month7ElectricKWH: number;
  month7GasKWH: number;
  month7ElectricBill: number;
  month7GasBill: number;
  month8Electric: string;
  month8Gas: string;
  month8ElectricKWH: number;
  month8GasKWH: number;
  month8ElectricBill: number;
  month8GasBill: number;
  month9Electric: string;
  month9Gas: string;
  month9ElectricKWH: number;
  month9GasKWH: number;
  month9ElectricBill: number;
  month9GasBill: number;
  month10Electric: string;
  month10Gas: string;
  month10ElectricKWH: number;
  month10GasKWH: number;
  month10ElectricBill: number;
  month10GasBill: number;
  month11Electric: string;
  month11Gas: string;
  month11ElectricKWH: number;
  month11GasKWH: number;
  month11ElectricBill: number;
  month11GasBill: number;
  month12Electric: string;
  month12Gas: string;
  month12ElectricKWH: number;
  month12GasKWH: number;
  month12ElectricBill: number;
  month12GasBill: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateEnergyCalculationRequestMutation = {
  __typename: "EnergyCalculationRequest";
  id: string;
  owner: string;
  homeCount?: number | null;
  DOEIDS?: Array<string | null> | null;
  name: string;
  email: string;
  address: string;
  userType: string;
  companyName?: string | null;
  kwhr: number;
  sqft: number;
  useTherms: boolean;
  useCCF: boolean;
  useBTU: boolean;
  monthlyCost: number;
  annualCost: number;
  annualKwhr: number;
  hasSolar: boolean;
  hasNatGas: boolean;
  responseUserECI: number;
  responseUserEUI: number;
  responseAvgECI: number;
  responseAvgEUI: number;
  chartUrl: string;
  acceptedTerms: string;
  acceptedPolicy: string;
  accepted?: boolean | null;
  region: string;
  housingUnit: string;
  renterOccupied: boolean;
  numberStories: string;
  homeBuildYear: number;
  solarSize: number;
  hasBattery: boolean;
  batterySize: number;
  HVACSystem: string;
  originalHVAC: boolean;
  waterHeater: string;
  originalWaterHeater: boolean;
  programmableThermostat: boolean;
  dualPaneWindows: boolean;
  EVChargingSystem: boolean;
  primaryEnergyServiceProvider: string;
  primaryEnergySource: string;
  primaryServiceAgreementId: string;
  primaryMeterNumber: string;
  primaryRateSchedule: string;
  secondaryEnergyServiceProvider: string;
  secondaryEnergySource: string;
  secondaryServiceAgreementId: string;
  secondaryMeterNumber: string;
  secondaryRateSchedule: string;
  headerOne: string;
  headerTwo: string;
  headerThree: string;
  month1Electric: string;
  month1Gas: string;
  month1ElectricKWH: number;
  month1GasKWH: number;
  month1ElectricBill: number;
  month1GasBill: number;
  month2Electric: string;
  month2Gas: string;
  month2ElectricKWH: number;
  month2GasKWH: number;
  month2ElectricBill: number;
  month2GasBill: number;
  month3Electric: string;
  month3Gas: string;
  month3ElectricKWH: number;
  month3GasKWH: number;
  month3ElectricBill: number;
  month3GasBill: number;
  month4Electric: string;
  month4Gas: string;
  month4ElectricKWH: number;
  month4GasKWH: number;
  month4ElectricBill: number;
  month4GasBill: number;
  month5Electric: string;
  month5Gas: string;
  month5ElectricKWH: number;
  month5GasKWH: number;
  month5ElectricBill: number;
  month5GasBill: number;
  month6Electric: string;
  month6Gas: string;
  month6ElectricKWH: number;
  month6GasKWH: number;
  month6ElectricBill: number;
  month6GasBill: number;
  month7Electric: string;
  month7Gas: string;
  month7ElectricKWH: number;
  month7GasKWH: number;
  month7ElectricBill: number;
  month7GasBill: number;
  month8Electric: string;
  month8Gas: string;
  month8ElectricKWH: number;
  month8GasKWH: number;
  month8ElectricBill: number;
  month8GasBill: number;
  month9Electric: string;
  month9Gas: string;
  month9ElectricKWH: number;
  month9GasKWH: number;
  month9ElectricBill: number;
  month9GasBill: number;
  month10Electric: string;
  month10Gas: string;
  month10ElectricKWH: number;
  month10GasKWH: number;
  month10ElectricBill: number;
  month10GasBill: number;
  month11Electric: string;
  month11Gas: string;
  month11ElectricKWH: number;
  month11GasKWH: number;
  month11ElectricBill: number;
  month11GasBill: number;
  month12Electric: string;
  month12Gas: string;
  month12ElectricKWH: number;
  month12GasKWH: number;
  month12ElectricBill: number;
  month12GasBill: number;
  createdAt: string;
  updatedAt: string;
};

export type DeleteEnergyCalculationRequestMutation = {
  __typename: "EnergyCalculationRequest";
  id: string;
  owner: string;
  homeCount?: number | null;
  DOEIDS?: Array<string | null> | null;
  name: string;
  email: string;
  address: string;
  userType: string;
  companyName?: string | null;
  kwhr: number;
  sqft: number;
  useTherms: boolean;
  useCCF: boolean;
  useBTU: boolean;
  monthlyCost: number;
  annualCost: number;
  annualKwhr: number;
  hasSolar: boolean;
  hasNatGas: boolean;
  responseUserECI: number;
  responseUserEUI: number;
  responseAvgECI: number;
  responseAvgEUI: number;
  chartUrl: string;
  acceptedTerms: string;
  acceptedPolicy: string;
  accepted?: boolean | null;
  region: string;
  housingUnit: string;
  renterOccupied: boolean;
  numberStories: string;
  homeBuildYear: number;
  solarSize: number;
  hasBattery: boolean;
  batterySize: number;
  HVACSystem: string;
  originalHVAC: boolean;
  waterHeater: string;
  originalWaterHeater: boolean;
  programmableThermostat: boolean;
  dualPaneWindows: boolean;
  EVChargingSystem: boolean;
  primaryEnergyServiceProvider: string;
  primaryEnergySource: string;
  primaryServiceAgreementId: string;
  primaryMeterNumber: string;
  primaryRateSchedule: string;
  secondaryEnergyServiceProvider: string;
  secondaryEnergySource: string;
  secondaryServiceAgreementId: string;
  secondaryMeterNumber: string;
  secondaryRateSchedule: string;
  headerOne: string;
  headerTwo: string;
  headerThree: string;
  month1Electric: string;
  month1Gas: string;
  month1ElectricKWH: number;
  month1GasKWH: number;
  month1ElectricBill: number;
  month1GasBill: number;
  month2Electric: string;
  month2Gas: string;
  month2ElectricKWH: number;
  month2GasKWH: number;
  month2ElectricBill: number;
  month2GasBill: number;
  month3Electric: string;
  month3Gas: string;
  month3ElectricKWH: number;
  month3GasKWH: number;
  month3ElectricBill: number;
  month3GasBill: number;
  month4Electric: string;
  month4Gas: string;
  month4ElectricKWH: number;
  month4GasKWH: number;
  month4ElectricBill: number;
  month4GasBill: number;
  month5Electric: string;
  month5Gas: string;
  month5ElectricKWH: number;
  month5GasKWH: number;
  month5ElectricBill: number;
  month5GasBill: number;
  month6Electric: string;
  month6Gas: string;
  month6ElectricKWH: number;
  month6GasKWH: number;
  month6ElectricBill: number;
  month6GasBill: number;
  month7Electric: string;
  month7Gas: string;
  month7ElectricKWH: number;
  month7GasKWH: number;
  month7ElectricBill: number;
  month7GasBill: number;
  month8Electric: string;
  month8Gas: string;
  month8ElectricKWH: number;
  month8GasKWH: number;
  month8ElectricBill: number;
  month8GasBill: number;
  month9Electric: string;
  month9Gas: string;
  month9ElectricKWH: number;
  month9GasKWH: number;
  month9ElectricBill: number;
  month9GasBill: number;
  month10Electric: string;
  month10Gas: string;
  month10ElectricKWH: number;
  month10GasKWH: number;
  month10ElectricBill: number;
  month10GasBill: number;
  month11Electric: string;
  month11Gas: string;
  month11ElectricKWH: number;
  month11GasKWH: number;
  month11ElectricBill: number;
  month11GasBill: number;
  month12Electric: string;
  month12Gas: string;
  month12ElectricKWH: number;
  month12GasKWH: number;
  month12ElectricBill: number;
  month12GasBill: number;
  createdAt: string;
  updatedAt: string;
};

export type GetAppStateQuery = {
  __typename: "AppState";
  maintenanceMode?: boolean | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type ListAppStatesQuery = {
  __typename: "ModelAppStateConnection";
  items: Array<{
    __typename: "AppState";
    maintenanceMode?: boolean | null;
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetBlogQuery = {
  __typename: "Blog";
  name: string;
  posts?: {
    __typename: "ModelPostConnection";
    items: Array<{
      __typename: "Post";
      title: string;
      subtitle: string;
      author: string;
      date: string;
      img: string;
      html: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      blogPostsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type ListBlogsQuery = {
  __typename: "ModelBlogConnection";
  items: Array<{
    __typename: "Blog";
    name: string;
    posts?: {
      __typename: "ModelPostConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type GetPostQuery = {
  __typename: "Post";
  title: string;
  subtitle: string;
  author: string;
  date: string;
  img: string;
  html: string;
  blog?: {
    __typename: "Blog";
    name: string;
    posts?: {
      __typename: "ModelPostConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  comments?: {
    __typename: "ModelCommentConnection";
    items: Array<{
      __typename: "Comment";
      content: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      postCommentsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  blogPostsId?: string | null;
};

export type ListPostsQuery = {
  __typename: "ModelPostConnection";
  items: Array<{
    __typename: "Post";
    title: string;
    subtitle: string;
    author: string;
    date: string;
    img: string;
    html: string;
    blog?: {
      __typename: "Blog";
      name: string;
      id: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    comments?: {
      __typename: "ModelCommentConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    blogPostsId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetCommentQuery = {
  __typename: "Comment";
  post?: {
    __typename: "Post";
    title: string;
    subtitle: string;
    author: string;
    date: string;
    img: string;
    html: string;
    blog?: {
      __typename: "Blog";
      name: string;
      id: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    comments?: {
      __typename: "ModelCommentConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    blogPostsId?: string | null;
  } | null;
  content: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  postCommentsId?: string | null;
};

export type ListCommentsQuery = {
  __typename: "ModelCommentConnection";
  items: Array<{
    __typename: "Comment";
    post?: {
      __typename: "Post";
      title: string;
      subtitle: string;
      author: string;
      date: string;
      img: string;
      html: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      blogPostsId?: string | null;
    } | null;
    content: string;
    id: string;
    createdAt: string;
    updatedAt: string;
    postCommentsId?: string | null;
  } | null>;
  nextToken?: string | null;
};

export type GetEnergyCalculationRequestQuery = {
  __typename: "EnergyCalculationRequest";
  id: string;
  owner: string;
  homeCount?: number | null;
  DOEIDS?: Array<string | null> | null;
  name: string;
  email: string;
  address: string;
  userType: string;
  companyName?: string | null;
  kwhr: number;
  sqft: number;
  useTherms: boolean;
  useCCF: boolean;
  useBTU: boolean;
  monthlyCost: number;
  annualCost: number;
  annualKwhr: number;
  hasSolar: boolean;
  hasNatGas: boolean;
  responseUserECI: number;
  responseUserEUI: number;
  responseAvgECI: number;
  responseAvgEUI: number;
  chartUrl: string;
  acceptedTerms: string;
  acceptedPolicy: string;
  accepted?: boolean | null;
  region: string;
  housingUnit: string;
  renterOccupied: boolean;
  numberStories: string;
  homeBuildYear: number;
  solarSize: number;
  hasBattery: boolean;
  batterySize: number;
  HVACSystem: string;
  originalHVAC: boolean;
  waterHeater: string;
  originalWaterHeater: boolean;
  programmableThermostat: boolean;
  dualPaneWindows: boolean;
  EVChargingSystem: boolean;
  primaryEnergyServiceProvider: string;
  primaryEnergySource: string;
  primaryServiceAgreementId: string;
  primaryMeterNumber: string;
  primaryRateSchedule: string;
  secondaryEnergyServiceProvider: string;
  secondaryEnergySource: string;
  secondaryServiceAgreementId: string;
  secondaryMeterNumber: string;
  secondaryRateSchedule: string;
  headerOne: string;
  headerTwo: string;
  headerThree: string;
  month1Electric: string;
  month1Gas: string;
  month1ElectricKWH: number;
  month1GasKWH: number;
  month1ElectricBill: number;
  month1GasBill: number;
  month2Electric: string;
  month2Gas: string;
  month2ElectricKWH: number;
  month2GasKWH: number;
  month2ElectricBill: number;
  month2GasBill: number;
  month3Electric: string;
  month3Gas: string;
  month3ElectricKWH: number;
  month3GasKWH: number;
  month3ElectricBill: number;
  month3GasBill: number;
  month4Electric: string;
  month4Gas: string;
  month4ElectricKWH: number;
  month4GasKWH: number;
  month4ElectricBill: number;
  month4GasBill: number;
  month5Electric: string;
  month5Gas: string;
  month5ElectricKWH: number;
  month5GasKWH: number;
  month5ElectricBill: number;
  month5GasBill: number;
  month6Electric: string;
  month6Gas: string;
  month6ElectricKWH: number;
  month6GasKWH: number;
  month6ElectricBill: number;
  month6GasBill: number;
  month7Electric: string;
  month7Gas: string;
  month7ElectricKWH: number;
  month7GasKWH: number;
  month7ElectricBill: number;
  month7GasBill: number;
  month8Electric: string;
  month8Gas: string;
  month8ElectricKWH: number;
  month8GasKWH: number;
  month8ElectricBill: number;
  month8GasBill: number;
  month9Electric: string;
  month9Gas: string;
  month9ElectricKWH: number;
  month9GasKWH: number;
  month9ElectricBill: number;
  month9GasBill: number;
  month10Electric: string;
  month10Gas: string;
  month10ElectricKWH: number;
  month10GasKWH: number;
  month10ElectricBill: number;
  month10GasBill: number;
  month11Electric: string;
  month11Gas: string;
  month11ElectricKWH: number;
  month11GasKWH: number;
  month11ElectricBill: number;
  month11GasBill: number;
  month12Electric: string;
  month12Gas: string;
  month12ElectricKWH: number;
  month12GasKWH: number;
  month12ElectricBill: number;
  month12GasBill: number;
  createdAt: string;
  updatedAt: string;
};

export type ListEnergyCalculationRequestsQuery = {
  __typename: "ModelEnergyCalculationRequestConnection";
  items: Array<{
    __typename: "EnergyCalculationRequest";
    id: string;
    owner: string;
    homeCount?: number | null;
    DOEIDS?: Array<string | null> | null;
    name: string;
    email: string;
    address: string;
    userType: string;
    companyName?: string | null;
    kwhr: number;
    sqft: number;
    useTherms: boolean;
    useCCF: boolean;
    useBTU: boolean;
    monthlyCost: number;
    annualCost: number;
    annualKwhr: number;
    hasSolar: boolean;
    hasNatGas: boolean;
    responseUserECI: number;
    responseUserEUI: number;
    responseAvgECI: number;
    responseAvgEUI: number;
    chartUrl: string;
    acceptedTerms: string;
    acceptedPolicy: string;
    accepted?: boolean | null;
    region: string;
    housingUnit: string;
    renterOccupied: boolean;
    numberStories: string;
    homeBuildYear: number;
    solarSize: number;
    hasBattery: boolean;
    batterySize: number;
    HVACSystem: string;
    originalHVAC: boolean;
    waterHeater: string;
    originalWaterHeater: boolean;
    programmableThermostat: boolean;
    dualPaneWindows: boolean;
    EVChargingSystem: boolean;
    primaryEnergyServiceProvider: string;
    primaryEnergySource: string;
    primaryServiceAgreementId: string;
    primaryMeterNumber: string;
    primaryRateSchedule: string;
    secondaryEnergyServiceProvider: string;
    secondaryEnergySource: string;
    secondaryServiceAgreementId: string;
    secondaryMeterNumber: string;
    secondaryRateSchedule: string;
    headerOne: string;
    headerTwo: string;
    headerThree: string;
    month1Electric: string;
    month1Gas: string;
    month1ElectricKWH: number;
    month1GasKWH: number;
    month1ElectricBill: number;
    month1GasBill: number;
    month2Electric: string;
    month2Gas: string;
    month2ElectricKWH: number;
    month2GasKWH: number;
    month2ElectricBill: number;
    month2GasBill: number;
    month3Electric: string;
    month3Gas: string;
    month3ElectricKWH: number;
    month3GasKWH: number;
    month3ElectricBill: number;
    month3GasBill: number;
    month4Electric: string;
    month4Gas: string;
    month4ElectricKWH: number;
    month4GasKWH: number;
    month4ElectricBill: number;
    month4GasBill: number;
    month5Electric: string;
    month5Gas: string;
    month5ElectricKWH: number;
    month5GasKWH: number;
    month5ElectricBill: number;
    month5GasBill: number;
    month6Electric: string;
    month6Gas: string;
    month6ElectricKWH: number;
    month6GasKWH: number;
    month6ElectricBill: number;
    month6GasBill: number;
    month7Electric: string;
    month7Gas: string;
    month7ElectricKWH: number;
    month7GasKWH: number;
    month7ElectricBill: number;
    month7GasBill: number;
    month8Electric: string;
    month8Gas: string;
    month8ElectricKWH: number;
    month8GasKWH: number;
    month8ElectricBill: number;
    month8GasBill: number;
    month9Electric: string;
    month9Gas: string;
    month9ElectricKWH: number;
    month9GasKWH: number;
    month9ElectricBill: number;
    month9GasBill: number;
    month10Electric: string;
    month10Gas: string;
    month10ElectricKWH: number;
    month10GasKWH: number;
    month10ElectricBill: number;
    month10GasBill: number;
    month11Electric: string;
    month11Gas: string;
    month11ElectricKWH: number;
    month11GasKWH: number;
    month11ElectricBill: number;
    month11GasBill: number;
    month12Electric: string;
    month12Gas: string;
    month12ElectricKWH: number;
    month12GasKWH: number;
    month12ElectricBill: number;
    month12GasBill: number;
    createdAt: string;
    updatedAt: string;
  } | null>;
  nextToken?: string | null;
};

export type OnCreateAppStateSubscription = {
  __typename: "AppState";
  maintenanceMode?: boolean | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAppStateSubscription = {
  __typename: "AppState";
  maintenanceMode?: boolean | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAppStateSubscription = {
  __typename: "AppState";
  maintenanceMode?: boolean | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateBlogSubscription = {
  __typename: "Blog";
  name: string;
  posts?: {
    __typename: "ModelPostConnection";
    items: Array<{
      __typename: "Post";
      title: string;
      subtitle: string;
      author: string;
      date: string;
      img: string;
      html: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      blogPostsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateBlogSubscription = {
  __typename: "Blog";
  name: string;
  posts?: {
    __typename: "ModelPostConnection";
    items: Array<{
      __typename: "Post";
      title: string;
      subtitle: string;
      author: string;
      date: string;
      img: string;
      html: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      blogPostsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteBlogSubscription = {
  __typename: "Blog";
  name: string;
  posts?: {
    __typename: "ModelPostConnection";
    items: Array<{
      __typename: "Post";
      title: string;
      subtitle: string;
      author: string;
      date: string;
      img: string;
      html: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      blogPostsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreatePostSubscription = {
  __typename: "Post";
  title: string;
  subtitle: string;
  author: string;
  date: string;
  img: string;
  html: string;
  blog?: {
    __typename: "Blog";
    name: string;
    posts?: {
      __typename: "ModelPostConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  comments?: {
    __typename: "ModelCommentConnection";
    items: Array<{
      __typename: "Comment";
      content: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      postCommentsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  blogPostsId?: string | null;
};

export type OnUpdatePostSubscription = {
  __typename: "Post";
  title: string;
  subtitle: string;
  author: string;
  date: string;
  img: string;
  html: string;
  blog?: {
    __typename: "Blog";
    name: string;
    posts?: {
      __typename: "ModelPostConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  comments?: {
    __typename: "ModelCommentConnection";
    items: Array<{
      __typename: "Comment";
      content: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      postCommentsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  blogPostsId?: string | null;
};

export type OnDeletePostSubscription = {
  __typename: "Post";
  title: string;
  subtitle: string;
  author: string;
  date: string;
  img: string;
  html: string;
  blog?: {
    __typename: "Blog";
    name: string;
    posts?: {
      __typename: "ModelPostConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
  } | null;
  comments?: {
    __typename: "ModelCommentConnection";
    items: Array<{
      __typename: "Comment";
      content: string;
      id: string;
      createdAt: string;
      updatedAt: string;
      postCommentsId?: string | null;
    } | null>;
    nextToken?: string | null;
  } | null;
  id: string;
  createdAt: string;
  updatedAt: string;
  blogPostsId?: string | null;
};

export type OnCreateCommentSubscription = {
  __typename: "Comment";
  post?: {
    __typename: "Post";
    title: string;
    subtitle: string;
    author: string;
    date: string;
    img: string;
    html: string;
    blog?: {
      __typename: "Blog";
      name: string;
      id: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    comments?: {
      __typename: "ModelCommentConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    blogPostsId?: string | null;
  } | null;
  content: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  postCommentsId?: string | null;
};

export type OnUpdateCommentSubscription = {
  __typename: "Comment";
  post?: {
    __typename: "Post";
    title: string;
    subtitle: string;
    author: string;
    date: string;
    img: string;
    html: string;
    blog?: {
      __typename: "Blog";
      name: string;
      id: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    comments?: {
      __typename: "ModelCommentConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    blogPostsId?: string | null;
  } | null;
  content: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  postCommentsId?: string | null;
};

export type OnDeleteCommentSubscription = {
  __typename: "Comment";
  post?: {
    __typename: "Post";
    title: string;
    subtitle: string;
    author: string;
    date: string;
    img: string;
    html: string;
    blog?: {
      __typename: "Blog";
      name: string;
      id: string;
      createdAt: string;
      updatedAt: string;
    } | null;
    comments?: {
      __typename: "ModelCommentConnection";
      nextToken?: string | null;
    } | null;
    id: string;
    createdAt: string;
    updatedAt: string;
    blogPostsId?: string | null;
  } | null;
  content: string;
  id: string;
  createdAt: string;
  updatedAt: string;
  postCommentsId?: string | null;
};

export type OnCreateEnergyCalculationRequestSubscription = {
  __typename: "EnergyCalculationRequest";
  id: string;
  owner: string;
  homeCount?: number | null;
  DOEIDS?: Array<string | null> | null;
  name: string;
  email: string;
  address: string;
  userType: string;
  companyName?: string | null;
  kwhr: number;
  sqft: number;
  useTherms: boolean;
  useCCF: boolean;
  useBTU: boolean;
  monthlyCost: number;
  annualCost: number;
  annualKwhr: number;
  hasSolar: boolean;
  hasNatGas: boolean;
  responseUserECI: number;
  responseUserEUI: number;
  responseAvgECI: number;
  responseAvgEUI: number;
  chartUrl: string;
  acceptedTerms: string;
  acceptedPolicy: string;
  accepted?: boolean | null;
  region: string;
  housingUnit: string;
  renterOccupied: boolean;
  numberStories: string;
  homeBuildYear: number;
  solarSize: number;
  hasBattery: boolean;
  batterySize: number;
  HVACSystem: string;
  originalHVAC: boolean;
  waterHeater: string;
  originalWaterHeater: boolean;
  programmableThermostat: boolean;
  dualPaneWindows: boolean;
  EVChargingSystem: boolean;
  primaryEnergyServiceProvider: string;
  primaryEnergySource: string;
  primaryServiceAgreementId: string;
  primaryMeterNumber: string;
  primaryRateSchedule: string;
  secondaryEnergyServiceProvider: string;
  secondaryEnergySource: string;
  secondaryServiceAgreementId: string;
  secondaryMeterNumber: string;
  secondaryRateSchedule: string;
  headerOne: string;
  headerTwo: string;
  headerThree: string;
  month1Electric: string;
  month1Gas: string;
  month1ElectricKWH: number;
  month1GasKWH: number;
  month1ElectricBill: number;
  month1GasBill: number;
  month2Electric: string;
  month2Gas: string;
  month2ElectricKWH: number;
  month2GasKWH: number;
  month2ElectricBill: number;
  month2GasBill: number;
  month3Electric: string;
  month3Gas: string;
  month3ElectricKWH: number;
  month3GasKWH: number;
  month3ElectricBill: number;
  month3GasBill: number;
  month4Electric: string;
  month4Gas: string;
  month4ElectricKWH: number;
  month4GasKWH: number;
  month4ElectricBill: number;
  month4GasBill: number;
  month5Electric: string;
  month5Gas: string;
  month5ElectricKWH: number;
  month5GasKWH: number;
  month5ElectricBill: number;
  month5GasBill: number;
  month6Electric: string;
  month6Gas: string;
  month6ElectricKWH: number;
  month6GasKWH: number;
  month6ElectricBill: number;
  month6GasBill: number;
  month7Electric: string;
  month7Gas: string;
  month7ElectricKWH: number;
  month7GasKWH: number;
  month7ElectricBill: number;
  month7GasBill: number;
  month8Electric: string;
  month8Gas: string;
  month8ElectricKWH: number;
  month8GasKWH: number;
  month8ElectricBill: number;
  month8GasBill: number;
  month9Electric: string;
  month9Gas: string;
  month9ElectricKWH: number;
  month9GasKWH: number;
  month9ElectricBill: number;
  month9GasBill: number;
  month10Electric: string;
  month10Gas: string;
  month10ElectricKWH: number;
  month10GasKWH: number;
  month10ElectricBill: number;
  month10GasBill: number;
  month11Electric: string;
  month11Gas: string;
  month11ElectricKWH: number;
  month11GasKWH: number;
  month11ElectricBill: number;
  month11GasBill: number;
  month12Electric: string;
  month12Gas: string;
  month12ElectricKWH: number;
  month12GasKWH: number;
  month12ElectricBill: number;
  month12GasBill: number;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateEnergyCalculationRequestSubscription = {
  __typename: "EnergyCalculationRequest";
  id: string;
  owner: string;
  homeCount?: number | null;
  DOEIDS?: Array<string | null> | null;
  name: string;
  email: string;
  address: string;
  userType: string;
  companyName?: string | null;
  kwhr: number;
  sqft: number;
  useTherms: boolean;
  useCCF: boolean;
  useBTU: boolean;
  monthlyCost: number;
  annualCost: number;
  annualKwhr: number;
  hasSolar: boolean;
  hasNatGas: boolean;
  responseUserECI: number;
  responseUserEUI: number;
  responseAvgECI: number;
  responseAvgEUI: number;
  chartUrl: string;
  acceptedTerms: string;
  acceptedPolicy: string;
  accepted?: boolean | null;
  region: string;
  housingUnit: string;
  renterOccupied: boolean;
  numberStories: string;
  homeBuildYear: number;
  solarSize: number;
  hasBattery: boolean;
  batterySize: number;
  HVACSystem: string;
  originalHVAC: boolean;
  waterHeater: string;
  originalWaterHeater: boolean;
  programmableThermostat: boolean;
  dualPaneWindows: boolean;
  EVChargingSystem: boolean;
  primaryEnergyServiceProvider: string;
  primaryEnergySource: string;
  primaryServiceAgreementId: string;
  primaryMeterNumber: string;
  primaryRateSchedule: string;
  secondaryEnergyServiceProvider: string;
  secondaryEnergySource: string;
  secondaryServiceAgreementId: string;
  secondaryMeterNumber: string;
  secondaryRateSchedule: string;
  headerOne: string;
  headerTwo: string;
  headerThree: string;
  month1Electric: string;
  month1Gas: string;
  month1ElectricKWH: number;
  month1GasKWH: number;
  month1ElectricBill: number;
  month1GasBill: number;
  month2Electric: string;
  month2Gas: string;
  month2ElectricKWH: number;
  month2GasKWH: number;
  month2ElectricBill: number;
  month2GasBill: number;
  month3Electric: string;
  month3Gas: string;
  month3ElectricKWH: number;
  month3GasKWH: number;
  month3ElectricBill: number;
  month3GasBill: number;
  month4Electric: string;
  month4Gas: string;
  month4ElectricKWH: number;
  month4GasKWH: number;
  month4ElectricBill: number;
  month4GasBill: number;
  month5Electric: string;
  month5Gas: string;
  month5ElectricKWH: number;
  month5GasKWH: number;
  month5ElectricBill: number;
  month5GasBill: number;
  month6Electric: string;
  month6Gas: string;
  month6ElectricKWH: number;
  month6GasKWH: number;
  month6ElectricBill: number;
  month6GasBill: number;
  month7Electric: string;
  month7Gas: string;
  month7ElectricKWH: number;
  month7GasKWH: number;
  month7ElectricBill: number;
  month7GasBill: number;
  month8Electric: string;
  month8Gas: string;
  month8ElectricKWH: number;
  month8GasKWH: number;
  month8ElectricBill: number;
  month8GasBill: number;
  month9Electric: string;
  month9Gas: string;
  month9ElectricKWH: number;
  month9GasKWH: number;
  month9ElectricBill: number;
  month9GasBill: number;
  month10Electric: string;
  month10Gas: string;
  month10ElectricKWH: number;
  month10GasKWH: number;
  month10ElectricBill: number;
  month10GasBill: number;
  month11Electric: string;
  month11Gas: string;
  month11ElectricKWH: number;
  month11GasKWH: number;
  month11ElectricBill: number;
  month11GasBill: number;
  month12Electric: string;
  month12Gas: string;
  month12ElectricKWH: number;
  month12GasKWH: number;
  month12ElectricBill: number;
  month12GasBill: number;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteEnergyCalculationRequestSubscription = {
  __typename: "EnergyCalculationRequest";
  id: string;
  owner: string;
  homeCount?: number | null;
  DOEIDS?: Array<string | null> | null;
  name: string;
  email: string;
  address: string;
  userType: string;
  companyName?: string | null;
  kwhr: number;
  sqft: number;
  useTherms: boolean;
  useCCF: boolean;
  useBTU: boolean;
  monthlyCost: number;
  annualCost: number;
  annualKwhr: number;
  hasSolar: boolean;
  hasNatGas: boolean;
  responseUserECI: number;
  responseUserEUI: number;
  responseAvgECI: number;
  responseAvgEUI: number;
  chartUrl: string;
  acceptedTerms: string;
  acceptedPolicy: string;
  accepted?: boolean | null;
  region: string;
  housingUnit: string;
  renterOccupied: boolean;
  numberStories: string;
  homeBuildYear: number;
  solarSize: number;
  hasBattery: boolean;
  batterySize: number;
  HVACSystem: string;
  originalHVAC: boolean;
  waterHeater: string;
  originalWaterHeater: boolean;
  programmableThermostat: boolean;
  dualPaneWindows: boolean;
  EVChargingSystem: boolean;
  primaryEnergyServiceProvider: string;
  primaryEnergySource: string;
  primaryServiceAgreementId: string;
  primaryMeterNumber: string;
  primaryRateSchedule: string;
  secondaryEnergyServiceProvider: string;
  secondaryEnergySource: string;
  secondaryServiceAgreementId: string;
  secondaryMeterNumber: string;
  secondaryRateSchedule: string;
  headerOne: string;
  headerTwo: string;
  headerThree: string;
  month1Electric: string;
  month1Gas: string;
  month1ElectricKWH: number;
  month1GasKWH: number;
  month1ElectricBill: number;
  month1GasBill: number;
  month2Electric: string;
  month2Gas: string;
  month2ElectricKWH: number;
  month2GasKWH: number;
  month2ElectricBill: number;
  month2GasBill: number;
  month3Electric: string;
  month3Gas: string;
  month3ElectricKWH: number;
  month3GasKWH: number;
  month3ElectricBill: number;
  month3GasBill: number;
  month4Electric: string;
  month4Gas: string;
  month4ElectricKWH: number;
  month4GasKWH: number;
  month4ElectricBill: number;
  month4GasBill: number;
  month5Electric: string;
  month5Gas: string;
  month5ElectricKWH: number;
  month5GasKWH: number;
  month5ElectricBill: number;
  month5GasBill: number;
  month6Electric: string;
  month6Gas: string;
  month6ElectricKWH: number;
  month6GasKWH: number;
  month6ElectricBill: number;
  month6GasBill: number;
  month7Electric: string;
  month7Gas: string;
  month7ElectricKWH: number;
  month7GasKWH: number;
  month7ElectricBill: number;
  month7GasBill: number;
  month8Electric: string;
  month8Gas: string;
  month8ElectricKWH: number;
  month8GasKWH: number;
  month8ElectricBill: number;
  month8GasBill: number;
  month9Electric: string;
  month9Gas: string;
  month9ElectricKWH: number;
  month9GasKWH: number;
  month9ElectricBill: number;
  month9GasBill: number;
  month10Electric: string;
  month10Gas: string;
  month10ElectricKWH: number;
  month10GasKWH: number;
  month10ElectricBill: number;
  month10GasBill: number;
  month11Electric: string;
  month11Gas: string;
  month11ElectricKWH: number;
  month11GasKWH: number;
  month11ElectricBill: number;
  month11GasBill: number;
  month12Electric: string;
  month12Gas: string;
  month12ElectricKWH: number;
  month12GasKWH: number;
  month12ElectricBill: number;
  month12GasBill: number;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateAppState(
    input: CreateAppStateInput,
    condition?: ModelAppStateConditionInput
  ): Promise<CreateAppStateMutation> {
    const statement = `mutation CreateAppState($input: CreateAppStateInput!, $condition: ModelAppStateConditionInput) {
        createAppState(input: $input, condition: $condition) {
          __typename
          maintenanceMode
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAppStateMutation>response.data.createAppState;
  }
  async UpdateAppState(
    input: UpdateAppStateInput,
    condition?: ModelAppStateConditionInput
  ): Promise<UpdateAppStateMutation> {
    const statement = `mutation UpdateAppState($input: UpdateAppStateInput!, $condition: ModelAppStateConditionInput) {
        updateAppState(input: $input, condition: $condition) {
          __typename
          maintenanceMode
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAppStateMutation>response.data.updateAppState;
  }
  async DeleteAppState(
    input: DeleteAppStateInput,
    condition?: ModelAppStateConditionInput
  ): Promise<DeleteAppStateMutation> {
    const statement = `mutation DeleteAppState($input: DeleteAppStateInput!, $condition: ModelAppStateConditionInput) {
        deleteAppState(input: $input, condition: $condition) {
          __typename
          maintenanceMode
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAppStateMutation>response.data.deleteAppState;
  }
  async CreateBlog(
    input: CreateBlogInput,
    condition?: ModelBlogConditionInput
  ): Promise<CreateBlogMutation> {
    const statement = `mutation CreateBlog($input: CreateBlogInput!, $condition: ModelBlogConditionInput) {
        createBlog(input: $input, condition: $condition) {
          __typename
          name
          posts {
            __typename
            items {
              __typename
              title
              subtitle
              author
              date
              img
              html
              id
              createdAt
              updatedAt
              blogPostsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBlogMutation>response.data.createBlog;
  }
  async UpdateBlog(
    input: UpdateBlogInput,
    condition?: ModelBlogConditionInput
  ): Promise<UpdateBlogMutation> {
    const statement = `mutation UpdateBlog($input: UpdateBlogInput!, $condition: ModelBlogConditionInput) {
        updateBlog(input: $input, condition: $condition) {
          __typename
          name
          posts {
            __typename
            items {
              __typename
              title
              subtitle
              author
              date
              img
              html
              id
              createdAt
              updatedAt
              blogPostsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBlogMutation>response.data.updateBlog;
  }
  async DeleteBlog(
    input: DeleteBlogInput,
    condition?: ModelBlogConditionInput
  ): Promise<DeleteBlogMutation> {
    const statement = `mutation DeleteBlog($input: DeleteBlogInput!, $condition: ModelBlogConditionInput) {
        deleteBlog(input: $input, condition: $condition) {
          __typename
          name
          posts {
            __typename
            items {
              __typename
              title
              subtitle
              author
              date
              img
              html
              id
              createdAt
              updatedAt
              blogPostsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBlogMutation>response.data.deleteBlog;
  }
  async CreatePost(
    input: CreatePostInput,
    condition?: ModelPostConditionInput
  ): Promise<CreatePostMutation> {
    const statement = `mutation CreatePost($input: CreatePostInput!, $condition: ModelPostConditionInput) {
        createPost(input: $input, condition: $condition) {
          __typename
          title
          subtitle
          author
          date
          img
          html
          blog {
            __typename
            name
            posts {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
          }
          comments {
            __typename
            items {
              __typename
              content
              id
              createdAt
              updatedAt
              postCommentsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
          blogPostsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreatePostMutation>response.data.createPost;
  }
  async UpdatePost(
    input: UpdatePostInput,
    condition?: ModelPostConditionInput
  ): Promise<UpdatePostMutation> {
    const statement = `mutation UpdatePost($input: UpdatePostInput!, $condition: ModelPostConditionInput) {
        updatePost(input: $input, condition: $condition) {
          __typename
          title
          subtitle
          author
          date
          img
          html
          blog {
            __typename
            name
            posts {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
          }
          comments {
            __typename
            items {
              __typename
              content
              id
              createdAt
              updatedAt
              postCommentsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
          blogPostsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdatePostMutation>response.data.updatePost;
  }
  async DeletePost(
    input: DeletePostInput,
    condition?: ModelPostConditionInput
  ): Promise<DeletePostMutation> {
    const statement = `mutation DeletePost($input: DeletePostInput!, $condition: ModelPostConditionInput) {
        deletePost(input: $input, condition: $condition) {
          __typename
          title
          subtitle
          author
          date
          img
          html
          blog {
            __typename
            name
            posts {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
          }
          comments {
            __typename
            items {
              __typename
              content
              id
              createdAt
              updatedAt
              postCommentsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
          blogPostsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeletePostMutation>response.data.deletePost;
  }
  async CreateComment(
    input: CreateCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<CreateCommentMutation> {
    const statement = `mutation CreateComment($input: CreateCommentInput!, $condition: ModelCommentConditionInput) {
        createComment(input: $input, condition: $condition) {
          __typename
          post {
            __typename
            title
            subtitle
            author
            date
            img
            html
            blog {
              __typename
              name
              id
              createdAt
              updatedAt
            }
            comments {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
            blogPostsId
          }
          content
          id
          createdAt
          updatedAt
          postCommentsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCommentMutation>response.data.createComment;
  }
  async UpdateComment(
    input: UpdateCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<UpdateCommentMutation> {
    const statement = `mutation UpdateComment($input: UpdateCommentInput!, $condition: ModelCommentConditionInput) {
        updateComment(input: $input, condition: $condition) {
          __typename
          post {
            __typename
            title
            subtitle
            author
            date
            img
            html
            blog {
              __typename
              name
              id
              createdAt
              updatedAt
            }
            comments {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
            blogPostsId
          }
          content
          id
          createdAt
          updatedAt
          postCommentsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCommentMutation>response.data.updateComment;
  }
  async DeleteComment(
    input: DeleteCommentInput,
    condition?: ModelCommentConditionInput
  ): Promise<DeleteCommentMutation> {
    const statement = `mutation DeleteComment($input: DeleteCommentInput!, $condition: ModelCommentConditionInput) {
        deleteComment(input: $input, condition: $condition) {
          __typename
          post {
            __typename
            title
            subtitle
            author
            date
            img
            html
            blog {
              __typename
              name
              id
              createdAt
              updatedAt
            }
            comments {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
            blogPostsId
          }
          content
          id
          createdAt
          updatedAt
          postCommentsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCommentMutation>response.data.deleteComment;
  }
  async CreateEnergyCalculationRequest(
    input: CreateEnergyCalculationRequestInput,
    condition?: ModelEnergyCalculationRequestConditionInput
  ): Promise<CreateEnergyCalculationRequestMutation> {
    const statement = `mutation CreateEnergyCalculationRequest($input: CreateEnergyCalculationRequestInput!, $condition: ModelEnergyCalculationRequestConditionInput) {
        createEnergyCalculationRequest(input: $input, condition: $condition) {
          __typename
          id
          owner
          homeCount
          DOEIDS
          name
          email
          address
          userType
          companyName
          kwhr
          sqft
          useTherms
          useCCF
          useBTU
          monthlyCost
          annualCost
          annualKwhr
          hasSolar
          hasNatGas
          responseUserECI
          responseUserEUI
          responseAvgECI
          responseAvgEUI
          chartUrl
          acceptedTerms
          acceptedPolicy
          accepted
          region
          housingUnit
          renterOccupied
          numberStories
          homeBuildYear
          solarSize
          hasBattery
          batterySize
          HVACSystem
          originalHVAC
          waterHeater
          originalWaterHeater
          programmableThermostat
          dualPaneWindows
          EVChargingSystem
          primaryEnergyServiceProvider
          primaryEnergySource
          primaryServiceAgreementId
          primaryMeterNumber
          primaryRateSchedule
          secondaryEnergyServiceProvider
          secondaryEnergySource
          secondaryServiceAgreementId
          secondaryMeterNumber
          secondaryRateSchedule
          headerOne
          headerTwo
          headerThree
          month1Electric
          month1Gas
          month1ElectricKWH
          month1GasKWH
          month1ElectricBill
          month1GasBill
          month2Electric
          month2Gas
          month2ElectricKWH
          month2GasKWH
          month2ElectricBill
          month2GasBill
          month3Electric
          month3Gas
          month3ElectricKWH
          month3GasKWH
          month3ElectricBill
          month3GasBill
          month4Electric
          month4Gas
          month4ElectricKWH
          month4GasKWH
          month4ElectricBill
          month4GasBill
          month5Electric
          month5Gas
          month5ElectricKWH
          month5GasKWH
          month5ElectricBill
          month5GasBill
          month6Electric
          month6Gas
          month6ElectricKWH
          month6GasKWH
          month6ElectricBill
          month6GasBill
          month7Electric
          month7Gas
          month7ElectricKWH
          month7GasKWH
          month7ElectricBill
          month7GasBill
          month8Electric
          month8Gas
          month8ElectricKWH
          month8GasKWH
          month8ElectricBill
          month8GasBill
          month9Electric
          month9Gas
          month9ElectricKWH
          month9GasKWH
          month9ElectricBill
          month9GasBill
          month10Electric
          month10Gas
          month10ElectricKWH
          month10GasKWH
          month10ElectricBill
          month10GasBill
          month11Electric
          month11Gas
          month11ElectricKWH
          month11GasKWH
          month11ElectricBill
          month11GasBill
          month12Electric
          month12Gas
          month12ElectricKWH
          month12GasKWH
          month12ElectricBill
          month12GasBill
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateEnergyCalculationRequestMutation>(
      response.data.createEnergyCalculationRequest
    );
  }
  async UpdateEnergyCalculationRequest(
    input: UpdateEnergyCalculationRequestInput,
    condition?: ModelEnergyCalculationRequestConditionInput
  ): Promise<UpdateEnergyCalculationRequestMutation> {
    const statement = `mutation UpdateEnergyCalculationRequest($input: UpdateEnergyCalculationRequestInput!, $condition: ModelEnergyCalculationRequestConditionInput) {
        updateEnergyCalculationRequest(input: $input, condition: $condition) {
          __typename
          id
          owner
          homeCount
          DOEIDS
          name
          email
          address
          userType
          companyName
          kwhr
          sqft
          useTherms
          useCCF
          useBTU
          monthlyCost
          annualCost
          annualKwhr
          hasSolar
          hasNatGas
          responseUserECI
          responseUserEUI
          responseAvgECI
          responseAvgEUI
          chartUrl
          acceptedTerms
          acceptedPolicy
          accepted
          region
          housingUnit
          renterOccupied
          numberStories
          homeBuildYear
          solarSize
          hasBattery
          batterySize
          HVACSystem
          originalHVAC
          waterHeater
          originalWaterHeater
          programmableThermostat
          dualPaneWindows
          EVChargingSystem
          primaryEnergyServiceProvider
          primaryEnergySource
          primaryServiceAgreementId
          primaryMeterNumber
          primaryRateSchedule
          secondaryEnergyServiceProvider
          secondaryEnergySource
          secondaryServiceAgreementId
          secondaryMeterNumber
          secondaryRateSchedule
          headerOne
          headerTwo
          headerThree
          month1Electric
          month1Gas
          month1ElectricKWH
          month1GasKWH
          month1ElectricBill
          month1GasBill
          month2Electric
          month2Gas
          month2ElectricKWH
          month2GasKWH
          month2ElectricBill
          month2GasBill
          month3Electric
          month3Gas
          month3ElectricKWH
          month3GasKWH
          month3ElectricBill
          month3GasBill
          month4Electric
          month4Gas
          month4ElectricKWH
          month4GasKWH
          month4ElectricBill
          month4GasBill
          month5Electric
          month5Gas
          month5ElectricKWH
          month5GasKWH
          month5ElectricBill
          month5GasBill
          month6Electric
          month6Gas
          month6ElectricKWH
          month6GasKWH
          month6ElectricBill
          month6GasBill
          month7Electric
          month7Gas
          month7ElectricKWH
          month7GasKWH
          month7ElectricBill
          month7GasBill
          month8Electric
          month8Gas
          month8ElectricKWH
          month8GasKWH
          month8ElectricBill
          month8GasBill
          month9Electric
          month9Gas
          month9ElectricKWH
          month9GasKWH
          month9ElectricBill
          month9GasBill
          month10Electric
          month10Gas
          month10ElectricKWH
          month10GasKWH
          month10ElectricBill
          month10GasBill
          month11Electric
          month11Gas
          month11ElectricKWH
          month11GasKWH
          month11ElectricBill
          month11GasBill
          month12Electric
          month12Gas
          month12ElectricKWH
          month12GasKWH
          month12ElectricBill
          month12GasBill
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateEnergyCalculationRequestMutation>(
      response.data.updateEnergyCalculationRequest
    );
  }
  async DeleteEnergyCalculationRequest(
    input: DeleteEnergyCalculationRequestInput,
    condition?: ModelEnergyCalculationRequestConditionInput
  ): Promise<DeleteEnergyCalculationRequestMutation> {
    const statement = `mutation DeleteEnergyCalculationRequest($input: DeleteEnergyCalculationRequestInput!, $condition: ModelEnergyCalculationRequestConditionInput) {
        deleteEnergyCalculationRequest(input: $input, condition: $condition) {
          __typename
          id
          owner
          homeCount
          DOEIDS
          name
          email
          address
          userType
          companyName
          kwhr
          sqft
          useTherms
          useCCF
          useBTU
          monthlyCost
          annualCost
          annualKwhr
          hasSolar
          hasNatGas
          responseUserECI
          responseUserEUI
          responseAvgECI
          responseAvgEUI
          chartUrl
          acceptedTerms
          acceptedPolicy
          accepted
          region
          housingUnit
          renterOccupied
          numberStories
          homeBuildYear
          solarSize
          hasBattery
          batterySize
          HVACSystem
          originalHVAC
          waterHeater
          originalWaterHeater
          programmableThermostat
          dualPaneWindows
          EVChargingSystem
          primaryEnergyServiceProvider
          primaryEnergySource
          primaryServiceAgreementId
          primaryMeterNumber
          primaryRateSchedule
          secondaryEnergyServiceProvider
          secondaryEnergySource
          secondaryServiceAgreementId
          secondaryMeterNumber
          secondaryRateSchedule
          headerOne
          headerTwo
          headerThree
          month1Electric
          month1Gas
          month1ElectricKWH
          month1GasKWH
          month1ElectricBill
          month1GasBill
          month2Electric
          month2Gas
          month2ElectricKWH
          month2GasKWH
          month2ElectricBill
          month2GasBill
          month3Electric
          month3Gas
          month3ElectricKWH
          month3GasKWH
          month3ElectricBill
          month3GasBill
          month4Electric
          month4Gas
          month4ElectricKWH
          month4GasKWH
          month4ElectricBill
          month4GasBill
          month5Electric
          month5Gas
          month5ElectricKWH
          month5GasKWH
          month5ElectricBill
          month5GasBill
          month6Electric
          month6Gas
          month6ElectricKWH
          month6GasKWH
          month6ElectricBill
          month6GasBill
          month7Electric
          month7Gas
          month7ElectricKWH
          month7GasKWH
          month7ElectricBill
          month7GasBill
          month8Electric
          month8Gas
          month8ElectricKWH
          month8GasKWH
          month8ElectricBill
          month8GasBill
          month9Electric
          month9Gas
          month9ElectricKWH
          month9GasKWH
          month9ElectricBill
          month9GasBill
          month10Electric
          month10Gas
          month10ElectricKWH
          month10GasKWH
          month10ElectricBill
          month10GasBill
          month11Electric
          month11Gas
          month11ElectricKWH
          month11GasKWH
          month11ElectricBill
          month11GasBill
          month12Electric
          month12Gas
          month12ElectricKWH
          month12GasKWH
          month12ElectricBill
          month12GasBill
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteEnergyCalculationRequestMutation>(
      response.data.deleteEnergyCalculationRequest
    );
  }
  async GetAppState(id: string): Promise<GetAppStateQuery> {
    const statement = `query GetAppState($id: ID!) {
        getAppState(id: $id) {
          __typename
          maintenanceMode
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAppStateQuery>response.data.getAppState;
  }
  async ListAppStates(
    filter?: ModelAppStateFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAppStatesQuery> {
    const statement = `query ListAppStates($filter: ModelAppStateFilterInput, $limit: Int, $nextToken: String) {
        listAppStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            maintenanceMode
            id
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAppStatesQuery>response.data.listAppStates;
  }
  async GetBlog(id: string): Promise<GetBlogQuery> {
    const statement = `query GetBlog($id: ID!) {
        getBlog(id: $id) {
          __typename
          name
          posts {
            __typename
            items {
              __typename
              title
              subtitle
              author
              date
              img
              html
              id
              createdAt
              updatedAt
              blogPostsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBlogQuery>response.data.getBlog;
  }
  async ListBlogs(
    filter?: ModelBlogFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBlogsQuery> {
    const statement = `query ListBlogs($filter: ModelBlogFilterInput, $limit: Int, $nextToken: String) {
        listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            name
            posts {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBlogsQuery>response.data.listBlogs;
  }
  async GetPost(id: string): Promise<GetPostQuery> {
    const statement = `query GetPost($id: ID!) {
        getPost(id: $id) {
          __typename
          title
          subtitle
          author
          date
          img
          html
          blog {
            __typename
            name
            posts {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
          }
          comments {
            __typename
            items {
              __typename
              content
              id
              createdAt
              updatedAt
              postCommentsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
          blogPostsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetPostQuery>response.data.getPost;
  }
  async ListPosts(
    filter?: ModelPostFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListPostsQuery> {
    const statement = `query ListPosts($filter: ModelPostFilterInput, $limit: Int, $nextToken: String) {
        listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            title
            subtitle
            author
            date
            img
            html
            blog {
              __typename
              name
              id
              createdAt
              updatedAt
            }
            comments {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
            blogPostsId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListPostsQuery>response.data.listPosts;
  }
  async GetComment(id: string): Promise<GetCommentQuery> {
    const statement = `query GetComment($id: ID!) {
        getComment(id: $id) {
          __typename
          post {
            __typename
            title
            subtitle
            author
            date
            img
            html
            blog {
              __typename
              name
              id
              createdAt
              updatedAt
            }
            comments {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
            blogPostsId
          }
          content
          id
          createdAt
          updatedAt
          postCommentsId
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCommentQuery>response.data.getComment;
  }
  async ListComments(
    filter?: ModelCommentFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCommentsQuery> {
    const statement = `query ListComments($filter: ModelCommentFilterInput, $limit: Int, $nextToken: String) {
        listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            post {
              __typename
              title
              subtitle
              author
              date
              img
              html
              id
              createdAt
              updatedAt
              blogPostsId
            }
            content
            id
            createdAt
            updatedAt
            postCommentsId
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCommentsQuery>response.data.listComments;
  }
  async GetEnergyCalculationRequest(
    id: string
  ): Promise<GetEnergyCalculationRequestQuery> {
    const statement = `query GetEnergyCalculationRequest($id: ID!) {
        getEnergyCalculationRequest(id: $id) {
          __typename
          id
          owner
          homeCount
          DOEIDS
          name
          email
          address
          userType
          companyName
          kwhr
          sqft
          useTherms
          useCCF
          useBTU
          monthlyCost
          annualCost
          annualKwhr
          hasSolar
          hasNatGas
          responseUserECI
          responseUserEUI
          responseAvgECI
          responseAvgEUI
          chartUrl
          acceptedTerms
          acceptedPolicy
          accepted
          region
          housingUnit
          renterOccupied
          numberStories
          homeBuildYear
          solarSize
          hasBattery
          batterySize
          HVACSystem
          originalHVAC
          waterHeater
          originalWaterHeater
          programmableThermostat
          dualPaneWindows
          EVChargingSystem
          primaryEnergyServiceProvider
          primaryEnergySource
          primaryServiceAgreementId
          primaryMeterNumber
          primaryRateSchedule
          secondaryEnergyServiceProvider
          secondaryEnergySource
          secondaryServiceAgreementId
          secondaryMeterNumber
          secondaryRateSchedule
          headerOne
          headerTwo
          headerThree
          month1Electric
          month1Gas
          month1ElectricKWH
          month1GasKWH
          month1ElectricBill
          month1GasBill
          month2Electric
          month2Gas
          month2ElectricKWH
          month2GasKWH
          month2ElectricBill
          month2GasBill
          month3Electric
          month3Gas
          month3ElectricKWH
          month3GasKWH
          month3ElectricBill
          month3GasBill
          month4Electric
          month4Gas
          month4ElectricKWH
          month4GasKWH
          month4ElectricBill
          month4GasBill
          month5Electric
          month5Gas
          month5ElectricKWH
          month5GasKWH
          month5ElectricBill
          month5GasBill
          month6Electric
          month6Gas
          month6ElectricKWH
          month6GasKWH
          month6ElectricBill
          month6GasBill
          month7Electric
          month7Gas
          month7ElectricKWH
          month7GasKWH
          month7ElectricBill
          month7GasBill
          month8Electric
          month8Gas
          month8ElectricKWH
          month8GasKWH
          month8ElectricBill
          month8GasBill
          month9Electric
          month9Gas
          month9ElectricKWH
          month9GasKWH
          month9ElectricBill
          month9GasBill
          month10Electric
          month10Gas
          month10ElectricKWH
          month10GasKWH
          month10ElectricBill
          month10GasBill
          month11Electric
          month11Gas
          month11ElectricKWH
          month11GasKWH
          month11ElectricBill
          month11GasBill
          month12Electric
          month12Gas
          month12ElectricKWH
          month12GasKWH
          month12ElectricBill
          month12GasBill
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetEnergyCalculationRequestQuery>(
      response.data.getEnergyCalculationRequest
    );
  }
  async ListEnergyCalculationRequests(
    filter?: ModelEnergyCalculationRequestFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListEnergyCalculationRequestsQuery> {
    const statement = `query ListEnergyCalculationRequests($filter: ModelEnergyCalculationRequestFilterInput, $limit: Int, $nextToken: String) {
        listEnergyCalculationRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            owner
            homeCount
            DOEIDS
            name
            email
            address
            userType
            companyName
            kwhr
            sqft
            useTherms
            useCCF
            useBTU
            monthlyCost
            annualCost
            annualKwhr
            hasSolar
            hasNatGas
            responseUserECI
            responseUserEUI
            responseAvgECI
            responseAvgEUI
            chartUrl
            acceptedTerms
            acceptedPolicy
            accepted
            region
            housingUnit
            renterOccupied
            numberStories
            homeBuildYear
            solarSize
            hasBattery
            batterySize
            HVACSystem
            originalHVAC
            waterHeater
            originalWaterHeater
            programmableThermostat
            dualPaneWindows
            EVChargingSystem
            primaryEnergyServiceProvider
            primaryEnergySource
            primaryServiceAgreementId
            primaryMeterNumber
            primaryRateSchedule
            secondaryEnergyServiceProvider
            secondaryEnergySource
            secondaryServiceAgreementId
            secondaryMeterNumber
            secondaryRateSchedule
            headerOne
            headerTwo
            headerThree
            month1Electric
            month1Gas
            month1ElectricKWH
            month1GasKWH
            month1ElectricBill
            month1GasBill
            month2Electric
            month2Gas
            month2ElectricKWH
            month2GasKWH
            month2ElectricBill
            month2GasBill
            month3Electric
            month3Gas
            month3ElectricKWH
            month3GasKWH
            month3ElectricBill
            month3GasBill
            month4Electric
            month4Gas
            month4ElectricKWH
            month4GasKWH
            month4ElectricBill
            month4GasBill
            month5Electric
            month5Gas
            month5ElectricKWH
            month5GasKWH
            month5ElectricBill
            month5GasBill
            month6Electric
            month6Gas
            month6ElectricKWH
            month6GasKWH
            month6ElectricBill
            month6GasBill
            month7Electric
            month7Gas
            month7ElectricKWH
            month7GasKWH
            month7ElectricBill
            month7GasBill
            month8Electric
            month8Gas
            month8ElectricKWH
            month8GasKWH
            month8ElectricBill
            month8GasBill
            month9Electric
            month9Gas
            month9ElectricKWH
            month9GasKWH
            month9ElectricBill
            month9GasBill
            month10Electric
            month10Gas
            month10ElectricKWH
            month10GasKWH
            month10ElectricBill
            month10GasBill
            month11Electric
            month11Gas
            month11ElectricKWH
            month11GasKWH
            month11ElectricBill
            month11GasBill
            month12Electric
            month12Gas
            month12ElectricKWH
            month12GasKWH
            month12ElectricBill
            month12GasBill
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListEnergyCalculationRequestsQuery>(
      response.data.listEnergyCalculationRequests
    );
  }
  OnCreateAppStateListener(
    filter?: ModelSubscriptionAppStateFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAppState">>
  > {
    const statement = `subscription OnCreateAppState($filter: ModelSubscriptionAppStateFilterInput) {
        onCreateAppState(filter: $filter) {
          __typename
          maintenanceMode
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateAppState">>
    >;
  }

  OnUpdateAppStateListener(
    filter?: ModelSubscriptionAppStateFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAppState">>
  > {
    const statement = `subscription OnUpdateAppState($filter: ModelSubscriptionAppStateFilterInput) {
        onUpdateAppState(filter: $filter) {
          __typename
          maintenanceMode
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateAppState">>
    >;
  }

  OnDeleteAppStateListener(
    filter?: ModelSubscriptionAppStateFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAppState">>
  > {
    const statement = `subscription OnDeleteAppState($filter: ModelSubscriptionAppStateFilterInput) {
        onDeleteAppState(filter: $filter) {
          __typename
          maintenanceMode
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteAppState">>
    >;
  }

  OnCreateBlogListener(
    filter?: ModelSubscriptionBlogFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateBlog">>
  > {
    const statement = `subscription OnCreateBlog($filter: ModelSubscriptionBlogFilterInput) {
        onCreateBlog(filter: $filter) {
          __typename
          name
          posts {
            __typename
            items {
              __typename
              title
              subtitle
              author
              date
              img
              html
              id
              createdAt
              updatedAt
              blogPostsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateBlog">>
    >;
  }

  OnUpdateBlogListener(
    filter?: ModelSubscriptionBlogFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateBlog">>
  > {
    const statement = `subscription OnUpdateBlog($filter: ModelSubscriptionBlogFilterInput) {
        onUpdateBlog(filter: $filter) {
          __typename
          name
          posts {
            __typename
            items {
              __typename
              title
              subtitle
              author
              date
              img
              html
              id
              createdAt
              updatedAt
              blogPostsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateBlog">>
    >;
  }

  OnDeleteBlogListener(
    filter?: ModelSubscriptionBlogFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteBlog">>
  > {
    const statement = `subscription OnDeleteBlog($filter: ModelSubscriptionBlogFilterInput) {
        onDeleteBlog(filter: $filter) {
          __typename
          name
          posts {
            __typename
            items {
              __typename
              title
              subtitle
              author
              date
              img
              html
              id
              createdAt
              updatedAt
              blogPostsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteBlog">>
    >;
  }

  OnCreatePostListener(
    filter?: ModelSubscriptionPostFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePost">>
  > {
    const statement = `subscription OnCreatePost($filter: ModelSubscriptionPostFilterInput) {
        onCreatePost(filter: $filter) {
          __typename
          title
          subtitle
          author
          date
          img
          html
          blog {
            __typename
            name
            posts {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
          }
          comments {
            __typename
            items {
              __typename
              content
              id
              createdAt
              updatedAt
              postCommentsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
          blogPostsId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreatePost">>
    >;
  }

  OnUpdatePostListener(
    filter?: ModelSubscriptionPostFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePost">>
  > {
    const statement = `subscription OnUpdatePost($filter: ModelSubscriptionPostFilterInput) {
        onUpdatePost(filter: $filter) {
          __typename
          title
          subtitle
          author
          date
          img
          html
          blog {
            __typename
            name
            posts {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
          }
          comments {
            __typename
            items {
              __typename
              content
              id
              createdAt
              updatedAt
              postCommentsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
          blogPostsId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdatePost">>
    >;
  }

  OnDeletePostListener(
    filter?: ModelSubscriptionPostFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePost">>
  > {
    const statement = `subscription OnDeletePost($filter: ModelSubscriptionPostFilterInput) {
        onDeletePost(filter: $filter) {
          __typename
          title
          subtitle
          author
          date
          img
          html
          blog {
            __typename
            name
            posts {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
          }
          comments {
            __typename
            items {
              __typename
              content
              id
              createdAt
              updatedAt
              postCommentsId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
          blogPostsId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeletePost">>
    >;
  }

  OnCreateCommentListener(
    filter?: ModelSubscriptionCommentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateComment">>
  > {
    const statement = `subscription OnCreateComment($filter: ModelSubscriptionCommentFilterInput) {
        onCreateComment(filter: $filter) {
          __typename
          post {
            __typename
            title
            subtitle
            author
            date
            img
            html
            blog {
              __typename
              name
              id
              createdAt
              updatedAt
            }
            comments {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
            blogPostsId
          }
          content
          id
          createdAt
          updatedAt
          postCommentsId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onCreateComment">>
    >;
  }

  OnUpdateCommentListener(
    filter?: ModelSubscriptionCommentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateComment">>
  > {
    const statement = `subscription OnUpdateComment($filter: ModelSubscriptionCommentFilterInput) {
        onUpdateComment(filter: $filter) {
          __typename
          post {
            __typename
            title
            subtitle
            author
            date
            img
            html
            blog {
              __typename
              name
              id
              createdAt
              updatedAt
            }
            comments {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
            blogPostsId
          }
          content
          id
          createdAt
          updatedAt
          postCommentsId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onUpdateComment">>
    >;
  }

  OnDeleteCommentListener(
    filter?: ModelSubscriptionCommentFilterInput
  ): Observable<
    SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteComment">>
  > {
    const statement = `subscription OnDeleteComment($filter: ModelSubscriptionCommentFilterInput) {
        onDeleteComment(filter: $filter) {
          __typename
          post {
            __typename
            title
            subtitle
            author
            date
            img
            html
            blog {
              __typename
              name
              id
              createdAt
              updatedAt
            }
            comments {
              __typename
              nextToken
            }
            id
            createdAt
            updatedAt
            blogPostsId
          }
          content
          id
          createdAt
          updatedAt
          postCommentsId
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<Pick<__SubscriptionContainer, "onDeleteComment">>
    >;
  }

  OnCreateEnergyCalculationRequestListener(
    filter?: ModelSubscriptionEnergyCalculationRequestFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onCreateEnergyCalculationRequest">
    >
  > {
    const statement = `subscription OnCreateEnergyCalculationRequest($filter: ModelSubscriptionEnergyCalculationRequestFilterInput) {
        onCreateEnergyCalculationRequest(filter: $filter) {
          __typename
          id
          owner
          homeCount
          DOEIDS
          name
          email
          address
          userType
          companyName
          kwhr
          sqft
          useTherms
          useCCF
          useBTU
          monthlyCost
          annualCost
          annualKwhr
          hasSolar
          hasNatGas
          responseUserECI
          responseUserEUI
          responseAvgECI
          responseAvgEUI
          chartUrl
          acceptedTerms
          acceptedPolicy
          accepted
          region
          housingUnit
          renterOccupied
          numberStories
          homeBuildYear
          solarSize
          hasBattery
          batterySize
          HVACSystem
          originalHVAC
          waterHeater
          originalWaterHeater
          programmableThermostat
          dualPaneWindows
          EVChargingSystem
          primaryEnergyServiceProvider
          primaryEnergySource
          primaryServiceAgreementId
          primaryMeterNumber
          primaryRateSchedule
          secondaryEnergyServiceProvider
          secondaryEnergySource
          secondaryServiceAgreementId
          secondaryMeterNumber
          secondaryRateSchedule
          headerOne
          headerTwo
          headerThree
          month1Electric
          month1Gas
          month1ElectricKWH
          month1GasKWH
          month1ElectricBill
          month1GasBill
          month2Electric
          month2Gas
          month2ElectricKWH
          month2GasKWH
          month2ElectricBill
          month2GasBill
          month3Electric
          month3Gas
          month3ElectricKWH
          month3GasKWH
          month3ElectricBill
          month3GasBill
          month4Electric
          month4Gas
          month4ElectricKWH
          month4GasKWH
          month4ElectricBill
          month4GasBill
          month5Electric
          month5Gas
          month5ElectricKWH
          month5GasKWH
          month5ElectricBill
          month5GasBill
          month6Electric
          month6Gas
          month6ElectricKWH
          month6GasKWH
          month6ElectricBill
          month6GasBill
          month7Electric
          month7Gas
          month7ElectricKWH
          month7GasKWH
          month7ElectricBill
          month7GasBill
          month8Electric
          month8Gas
          month8ElectricKWH
          month8GasKWH
          month8ElectricBill
          month8GasBill
          month9Electric
          month9Gas
          month9ElectricKWH
          month9GasKWH
          month9ElectricBill
          month9GasBill
          month10Electric
          month10Gas
          month10ElectricKWH
          month10GasKWH
          month10ElectricBill
          month10GasBill
          month11Electric
          month11Gas
          month11ElectricKWH
          month11GasKWH
          month11ElectricBill
          month11GasBill
          month12Electric
          month12Gas
          month12ElectricKWH
          month12GasKWH
          month12ElectricBill
          month12GasBill
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onCreateEnergyCalculationRequest">
      >
    >;
  }

  OnUpdateEnergyCalculationRequestListener(
    filter?: ModelSubscriptionEnergyCalculationRequestFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onUpdateEnergyCalculationRequest">
    >
  > {
    const statement = `subscription OnUpdateEnergyCalculationRequest($filter: ModelSubscriptionEnergyCalculationRequestFilterInput) {
        onUpdateEnergyCalculationRequest(filter: $filter) {
          __typename
          id
          owner
          homeCount
          DOEIDS
          name
          email
          address
          userType
          companyName
          kwhr
          sqft
          useTherms
          useCCF
          useBTU
          monthlyCost
          annualCost
          annualKwhr
          hasSolar
          hasNatGas
          responseUserECI
          responseUserEUI
          responseAvgECI
          responseAvgEUI
          chartUrl
          acceptedTerms
          acceptedPolicy
          accepted
          region
          housingUnit
          renterOccupied
          numberStories
          homeBuildYear
          solarSize
          hasBattery
          batterySize
          HVACSystem
          originalHVAC
          waterHeater
          originalWaterHeater
          programmableThermostat
          dualPaneWindows
          EVChargingSystem
          primaryEnergyServiceProvider
          primaryEnergySource
          primaryServiceAgreementId
          primaryMeterNumber
          primaryRateSchedule
          secondaryEnergyServiceProvider
          secondaryEnergySource
          secondaryServiceAgreementId
          secondaryMeterNumber
          secondaryRateSchedule
          headerOne
          headerTwo
          headerThree
          month1Electric
          month1Gas
          month1ElectricKWH
          month1GasKWH
          month1ElectricBill
          month1GasBill
          month2Electric
          month2Gas
          month2ElectricKWH
          month2GasKWH
          month2ElectricBill
          month2GasBill
          month3Electric
          month3Gas
          month3ElectricKWH
          month3GasKWH
          month3ElectricBill
          month3GasBill
          month4Electric
          month4Gas
          month4ElectricKWH
          month4GasKWH
          month4ElectricBill
          month4GasBill
          month5Electric
          month5Gas
          month5ElectricKWH
          month5GasKWH
          month5ElectricBill
          month5GasBill
          month6Electric
          month6Gas
          month6ElectricKWH
          month6GasKWH
          month6ElectricBill
          month6GasBill
          month7Electric
          month7Gas
          month7ElectricKWH
          month7GasKWH
          month7ElectricBill
          month7GasBill
          month8Electric
          month8Gas
          month8ElectricKWH
          month8GasKWH
          month8ElectricBill
          month8GasBill
          month9Electric
          month9Gas
          month9ElectricKWH
          month9GasKWH
          month9ElectricBill
          month9GasBill
          month10Electric
          month10Gas
          month10ElectricKWH
          month10GasKWH
          month10ElectricBill
          month10GasBill
          month11Electric
          month11Gas
          month11ElectricKWH
          month11GasKWH
          month11ElectricBill
          month11GasBill
          month12Electric
          month12Gas
          month12ElectricKWH
          month12GasKWH
          month12ElectricBill
          month12GasBill
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onUpdateEnergyCalculationRequest">
      >
    >;
  }

  OnDeleteEnergyCalculationRequestListener(
    filter?: ModelSubscriptionEnergyCalculationRequestFilterInput
  ): Observable<
    SubscriptionResponse<
      Pick<__SubscriptionContainer, "onDeleteEnergyCalculationRequest">
    >
  > {
    const statement = `subscription OnDeleteEnergyCalculationRequest($filter: ModelSubscriptionEnergyCalculationRequestFilterInput) {
        onDeleteEnergyCalculationRequest(filter: $filter) {
          __typename
          id
          owner
          homeCount
          DOEIDS
          name
          email
          address
          userType
          companyName
          kwhr
          sqft
          useTherms
          useCCF
          useBTU
          monthlyCost
          annualCost
          annualKwhr
          hasSolar
          hasNatGas
          responseUserECI
          responseUserEUI
          responseAvgECI
          responseAvgEUI
          chartUrl
          acceptedTerms
          acceptedPolicy
          accepted
          region
          housingUnit
          renterOccupied
          numberStories
          homeBuildYear
          solarSize
          hasBattery
          batterySize
          HVACSystem
          originalHVAC
          waterHeater
          originalWaterHeater
          programmableThermostat
          dualPaneWindows
          EVChargingSystem
          primaryEnergyServiceProvider
          primaryEnergySource
          primaryServiceAgreementId
          primaryMeterNumber
          primaryRateSchedule
          secondaryEnergyServiceProvider
          secondaryEnergySource
          secondaryServiceAgreementId
          secondaryMeterNumber
          secondaryRateSchedule
          headerOne
          headerTwo
          headerThree
          month1Electric
          month1Gas
          month1ElectricKWH
          month1GasKWH
          month1ElectricBill
          month1GasBill
          month2Electric
          month2Gas
          month2ElectricKWH
          month2GasKWH
          month2ElectricBill
          month2GasBill
          month3Electric
          month3Gas
          month3ElectricKWH
          month3GasKWH
          month3ElectricBill
          month3GasBill
          month4Electric
          month4Gas
          month4ElectricKWH
          month4GasKWH
          month4ElectricBill
          month4GasBill
          month5Electric
          month5Gas
          month5ElectricKWH
          month5GasKWH
          month5ElectricBill
          month5GasBill
          month6Electric
          month6Gas
          month6ElectricKWH
          month6GasKWH
          month6ElectricBill
          month6GasBill
          month7Electric
          month7Gas
          month7ElectricKWH
          month7GasKWH
          month7ElectricBill
          month7GasBill
          month8Electric
          month8Gas
          month8ElectricKWH
          month8GasKWH
          month8ElectricBill
          month8GasBill
          month9Electric
          month9Gas
          month9ElectricKWH
          month9GasKWH
          month9ElectricBill
          month9GasBill
          month10Electric
          month10Gas
          month10ElectricKWH
          month10GasKWH
          month10ElectricBill
          month10GasBill
          month11Electric
          month11Gas
          month11ElectricKWH
          month11GasKWH
          month11ElectricBill
          month11GasBill
          month12Electric
          month12Gas
          month12ElectricKWH
          month12GasKWH
          month12ElectricBill
          month12GasBill
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    return API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    ) as Observable<
      SubscriptionResponse<
        Pick<__SubscriptionContainer, "onDeleteEnergyCalculationRequest">
      >
    >;
  }
}
