import { Injectable } from "@angular/core";
import { AuthenticatorService } from "@aws-amplify/ui-angular";
import { BehaviorSubject, Observable, map } from "rxjs";

import { APIService } from "../API.service";
@Injectable({
  providedIn: "root",
})
export class MaintenanceModeService {
  maintenanceMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  isMaintenanceMode: Observable<boolean> = this.maintenanceMode.asObservable();
  shouldDisplayCalc: Observable<boolean> = this.maintenanceMode
    .asObservable()
    .pipe(map((result) => !result));
  constructor(private api: APIService,public authenticator: AuthenticatorService) { }
  
}
