<div
  class="app-splash-screen"
  [ngStyle]="{ left: windowWidth }"
  *ngIf="showSplash"
>
  <div class="app-splash-inner">
    <div class="app-logo"></div>
    
    <div class="app-loader"></div>
  </div>
</div>