import {
  APP_INITIALIZER,
  NgModule,
  Optional,
  PLATFORM_ID,
} from "@angular/core";
import {
  BrowserModule,
  BrowserTransferStateModule,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RESPONSE } from "@nguniversal/express-engine/tokens";
import { isPlatformServer } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ComponentsModule } from "./components/components.module";
import { environment } from "../environments/environment";
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
} from "@angular/forms";

import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { AmplifyAuthenticatorModule } from "@aws-amplify/ui-angular";
import awsconfig from "../aws-exports";
import { Amplify, Notifications } from "aws-amplify";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormlyFieldConfig, FormlyModule } from "@ngx-formly/core";
import { FormlyIonicModule } from "@ngx-formly/ionic";
import { HeaderWrapperComponent } from "./components/calculator/forms/header-wrapper.component";
import { TitleWrapperComponent } from "./components/calculator/forms/title-wrapper.component";
import { SplashscreenComponent } from "./components/splashscreen/splashscreen.component";

Amplify.configure(awsconfig);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function minLengthValidationMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `Should have atleast ${field.props.minLength} characters`;
}

export function maxLengthValidationMessage(
  error: any,
  field: FormlyFieldConfig
) {
  return `This value should be less than ${field.props.maxLength} characters`;
}

export function minValidationMessage(error: any, field: FormlyFieldConfig) {
  return `This value should be more than ${field.props.min}`;
}

export function maxValidationMessage(error: any, field: FormlyFieldConfig) {
  return `This value should be less than ${field.props.max}`;
}

export function EmailValidator(control: FormControl): ValidationErrors {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(control.value)
    ? null
    : { email: true };
}

export function EmailValidatorMessage(err, field: FormlyFieldConfig) {
  return `"${field.formControl.value}" is not a valid Email Address`;
}

@NgModule({
  declarations: [AppComponent, SplashscreenComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    BrowserTransferStateModule,
    IonicModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    AmplifyAuthenticatorModule,
    FormlyModule.forRoot({
      wrappers: [
        { name: "header", component: HeaderWrapperComponent },
        { name: "title", component: TitleWrapperComponent },
      ],
      validators: [{ name: "email", validation: EmailValidator }],
      validationMessages: [
        { name: "required", message: "This field is required" },
        { name: "minLength", message: minLengthValidationMessage },
        { name: "maxLength", message: maxLengthValidationMessage },
        { name: "min", message: minValidationMessage },
        { name: "max", message: maxValidationMessage },
        { name: "email", message: EmailValidatorMessage },
      ],
    }),
    FormlyIonicModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: APP_INITIALIZER,
      useFactory: (platformId: object, response: any) => {
        return () => {
          // In the server.ts we added a custom response header with information about the device requesting the app
          if (isPlatformServer(platformId)) {
            if (response && response !== null) {
              // Get custom header from the response sent from the server.ts
              const mobileDeviceHeader = response.get("mobile-device");

              // Set Ionic config mode?
            }
          }
        };
      },
      deps: [PLATFORM_ID, [new Optional(), RESPONSE]],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
