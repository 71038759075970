import { Component, OnInit } from '@angular/core';

@Component({
  selector: "app-splashscreen",
  templateUrl: "./splashscreen.component.html",
  styleUrls: ["./splashscreen.component.scss"],
})
export class SplashscreenComponent implements OnInit {
  windowWidth: string;
  showSplash = true;

  ngOnInit(): void {
    setTimeout(() => {
      this.windowWidth = "-" + window.innerWidth + "px";

      setTimeout(() => {
        this.showSplash = !this.showSplash;
      }, 500);
    }, 3000);
  }
}